@if (!completed) {
  <div
    class="padding-normal"
    om-layout="vertical"
  >
    <strong>Chronic care management time</strong>
    <div>
      <span class="chronic-care-management-time-explain-text">Only include time spent on chronic care management</span>
      <div>
        <input
          class="chronic-care-management-time-input"
          type="number"
          name="chronicCareManagementTime"
          [formControl]="chronicCareManagementTimeControl"
          omgInputText
        />
        minutes
      </div>
      @if (showErrorForControl(chronicCareManagementTimeControl)) {
        <div>
          @if (chronicCareManagementTimeControl.errors.min || chronicCareManagementTimeControl.errors.required) {
            <div class="om-messages">Add time spent</div>
          }
        </div>
      }
    </div>
  </div>
}
@if (!completed) {
  <div
    class="padding-normal chronic-care-management-summary-container"
    om-layout="vertical"
  >
    <strong>Chronic care management summary</strong>
    <omg-chart-text-box
      quillFormat="text"
      [control]="summaryControl"
    ></omg-chart-text-box>
    @if (showErrorForControl(summaryControl)) {
      <div>
        @if (summaryControl.errors.required) {
          <div class="om-messages">Add a summary</div>
        }
      </div>
    }
  </div>
}
@if (completed) {
  <div
    class="padding-normal"
    om-layout="vertical"
  >
    <strong>Chronic care management summary</strong>
    <span>{{ programVisit?.formData['time'] }} minutes</span>
    <p [innerHTML]="programVisit?.summaryOfVisit | safe: 'html'"></p>
  </div>
}
