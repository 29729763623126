import { Component, effect, ElementRef, viewChild } from '@angular/core';
import { SharedModule } from '@app/shared';
import { PrivacyBannerService } from '../../shared/privacy-banner.service';
import { AnalyticsEvent, AnalyticsService } from '@app/core';

@Component({
  selector: 'omg-privacy-banner',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './privacy-banner.component.html',
  styleUrl: './privacy-banner.component.scss',
})
export class PrivacyBannerComponent {
  private privacyBannerElement =
    viewChild.required<ElementRef>('privacyBanner');
  readonly BANNER_HEIGHT_VAR = '--privacy-banner-height';

  constructor(
    private privacyBannerService: PrivacyBannerService,
    private analyticsService: AnalyticsService,
  ) {
    effect(onCleanup => {
      const element = this.privacyBannerElement()?.nativeElement;
      if (!element) return;

      const resizeObserver = new ResizeObserver(entries => {
        entries?.forEach(entry => {
          const height = entry.target.getBoundingClientRect().height;
          this.setBannerHeight(height);
        });
      });
      this.analyticsService.track(AnalyticsEvent.PrivacyBannerDisplayed);

      resizeObserver.observe(element);
      onCleanup(() => {
        this.setBannerHeight(0);
        resizeObserver?.disconnect();
      });
    });
  }

  protected onAcknowledge(): void {
    this.privacyBannerService.acknowledge();
    this.setBannerHeight(0);
    this.analyticsService.track(AnalyticsEvent.PrivacyBannerAcknowledged);
  }

  protected setBannerHeight(height: number): void {
    document.documentElement.style.setProperty(
      this.BANNER_HEIGHT_VAR,
      `${height}px`,
    );
  }
}
