import { environment } from '@environments/environment';
import * as Sentry from '@sentry/angular-ivy';
import { HttpClient } from '@sentry/integrations';
import { scrubURL } from './error-utils';
import { Profile } from '../profile';

export const SentryErrorHandler = Sentry.createErrorHandler({
  showDialog: false,
});

export function initializeSentry(): void {
  Sentry.init({
    enabled: environment.sentry.enabled,
    environment: environment.sentry.environment,
    dsn: environment.sentry.dsn,
    release: environment.codeVersion,
    ignoreErrors: ['Object captured as exception'],
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new HttpClient(),
    ],
    tracesSampleRate: 0,
    beforeSend: event => {
      if (event.request?.url) {
        const url = new URL(event.request.url);
        const scrubbed = scrubURL(url);
        event.request.url = scrubbed;
        if (event.tags) {
          event.tags.transaction = scrubbed;
        }
      }
      return event;
    },
  });
}

export function initializeSentryUserContext(user: Profile): void {
  Sentry.setUser({
    id: user.id,
  });
}
