import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Apollo } from 'apollo-angular';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';

import { PatientSelectors } from '@app/core';
import { patientPrimaryAddressStateQuery } from '@app/features/pdmp/components/pdmp-dialogue-banner/pdmp-dialogue-banner.component';
import {
  AbstractRx,
  PatientPrimaryAddressStateResponse,
} from '@app/features/pdmp/shared/pdmp.type';
import {
  hasControlledSubstanceFilter,
  mapPatientPrimaryAddressStateToString,
} from '@app/features/pdmp/shared/pdmp.utils';
import { PatientPharmacySelectors } from '@app/modules/pharmacy-picker/store/patient-pharmacy.selectors';
import { filterTruthy } from '@app/utils';

import { CartDetailsProvider } from './cart-details-provider.interface';

export interface CartState {
  cartItems: AbstractRx[];
  cartId: number;
}

/**
 * Service which contains the business logic of PDMP.
 */
@Injectable()
export class PDMPService implements OnDestroy {
  private readonly defaultState: PDMPRxCheckoutState = {
    cartId: 0,
    pdmpReviewRequired: false,
    cartItems: [],
  };
  private readonly rxCartStateSubject =
    new BehaviorSubject<PDMPRxCheckoutState>(this.defaultState);

  private readonly tearDown = new Subject<void>();

  constructor(
    private apollo: Apollo,
    @Inject('CartDetailsProvider') rxCartProvider: CartDetailsProvider,
    private patientPharmacySelectors: PatientPharmacySelectors,
    private patientSelectors: PatientSelectors,
  ) {
    this.setupListeners(rxCartProvider.rxCartState$);
  }

  ngOnDestroy(): void {
    this.tearDown.next();
    this.tearDown.complete();
  }

  private get state(): PDMPRxCheckoutState {
    return this.rxCartStateSubject.value;
  }

  // SELECTORS ----

  /**
   * Observable which emits true if this cart has a controlled substance.
   */
  get pdmpReviewRequired$(): Observable<boolean> {
    return this.rxCartStateSubject.pipe(
      map(state => state.pdmpReviewRequired),
      distinctUntilChanged(),
    );
  }

  /**
   * Observable of the states to which the cart's controlled substances are being prescribed.
   */
  get controlledSubstanceStates$(): Observable<string[]> {
    return this.rxCartStateSubject.pipe(
      map(state => state.cartItems),
      switchMap(items =>
        combineLatest(
          items.filter(hasControlledSubstanceFilter).map(item =>
            'pharmacy' in item
              ? of(item.pharmacy) //From abstract rx
              : this.patientPharmacySelectors.pharmacy(item.pharmacyId!).pipe(
                  filterTruthy(),
                  map(res => res!.pharmacy),
                ),
          ),
        ),
      ),
      switchMap(pharmacies =>
        combineLatest(
          pharmacies.map(({ isMailOrder, address }) =>
            isMailOrder
              ? this.patientPrimaryAddressState$() // sub patient's primary state if delivery
              : of(address.state),
          ),
        ),
      ),
      map(states => [...new Set(states)].sort()), // To unique states
    );
  }

  get cartId$(): Observable<number> {
    return this.rxCartStateSubject.pipe(
      map(state => state.cartId),
      distinctUntilChanged(),
    );
  }

  /**
   * Sets up this service's configuration. This should be called in ngOnInit() within a component before this
   * class is used.
   */
  private setupListeners(rxCartState: Observable<CartState>): void {
    this.setupCartIdSubscription(rxCartState);
    this.setupCartItemsChangeSubscription(rxCartState);
  }

  /**
   * Setup subscription to listen to changes of the cart.
   *
   * @param rxCartItems -- the current Rx items in the cart.
   */
  private setupCartItemsChangeSubscription(
    rxCartItems: Observable<CartState>,
  ): void {
    rxCartItems
      .pipe(
        takeUntil(this.tearDown),
        map(cartState => cartState.cartItems),
        // only send update if cartItems have actually changed
        filter(newCartItems =>
          PDMPService.cartItemsChanged(this.state.cartItems, newCartItems),
        ),
      )
      .subscribe({
        next: cartItems => {
          const cartHasControlledSubstances = !!cartItems?.some(
            item => item.require2Fa,
          );

          this.rxCartStateSubject.next({
            ...this.state,
            cartItems: cartItems,
            pdmpReviewRequired: cartHasControlledSubstances,
          });
        },
      });
  }

  private setupCartIdSubscription(rxCartState: Observable<CartState>): void {
    rxCartState
      .pipe(
        takeUntil(this.tearDown),
        map(cartState => cartState.cartId),
        distinctUntilChanged(),
      )
      .subscribe({
        next: cartId => {
          this.rxCartStateSubject.next({
            ...this.state,
            cartId,
          });
        },
      });
  }

  private static cartItemsChanged(
    oldCartItems: AbstractRx[],
    newCartItems: AbstractRx[],
  ): boolean {
    return JSON.stringify(oldCartItems) !== JSON.stringify(newCartItems);
  }

  private patientPrimaryAddressState$(): Observable<string> {
    return this.patientSelectors.patientId.pipe(
      filterTruthy(),
      switchMap(patientId =>
        this.apollo.use('onelife').query<PatientPrimaryAddressStateResponse>({
          query: patientPrimaryAddressStateQuery,
          variables: { id: patientId },
        }),
      ),
      map(mapPatientPrimaryAddressStateToString),
    );
  }
}

interface PDMPRxCheckoutState {
  cartId: number;
  pdmpReviewRequired: boolean; // does the pdmp report and 'ready-to-sign' need to be shown?
  cartItems: AbstractRx[]; // the current Rx items in the cart.
}
