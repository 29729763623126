// @ts-strict-ignore
import * as mixpanel from 'mixpanel-browser';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { filterTruthy, snakeCase } from '@app/utils';

import { ConfigService } from '../config/config.service';
import { PatientSelectors } from '../patient';
import { ProfileSelectors } from '../profile';
import { AnalyticsEvent, TrackEventProperties } from './analytics.type';
import { FeatureFlagWhitelist } from './feature-flag-whitelist';
import { LaunchDarklyExperiment } from './launch-darkly-experiment';

interface MixPanelConfig {
  token: string;
  application: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  instanceProperties: Partial<TrackEventProperties>;

  private mixpanelAPI: mixpanel.Mixpanel = mixpanel;
  private _initialized = false;

  constructor(
    config: ConfigService,
    private profileSelectors: ProfileSelectors,
    private patientSelectors: PatientSelectors,
    private launchdarkly: LaunchDarklyService,
  ) {
    this.initialize(config.environment.mixPanel);
  }

  get initialized() {
    return this._initialized;
  }

  get patientId$(): Observable<number> {
    return this.patientSelectors.patientId.pipe(filterTruthy(), take(1));
  }

  /**
   * Identifies a user with a unique ID to track user activity across devices.
   *
   * @param profileId A string that uniquely identifies a user.
   *
   https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelidentify
  */
  private setIdentifier(): void {
    this.profileSelectors.profileId
      .pipe(filterTruthy(), take(1))
      .subscribe(profileId =>
        this.mixpanelAPI.identify(profileId ? `${profileId}` : ''),
      );
  }

  /**
   * Registers a set of super properties, which are included with all events.
   *
   * @param properties (Type Object) An associative array of properties to store about the user
   *
   * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelregister
   */
  private setSuperProperties(): void {
    this.patientId$.subscribe(patientId => {
      this.mixpanelAPI.register({
        ...this.instanceProperties,
        patientId,
      });
    });
  }

  private initialize(config: MixPanelConfig): void {
    // Initializing the mixpanel SDK
    // https://developer.mixpanel.com/docs/javascript-quickstart#installation-option-1-npmyarn
    if (!config.token || !this.mixpanelAPI || this.initialized) {
      return;
    }

    this.mixpanelAPI.init(config.token, {}, '');

    this.instanceProperties = {
      sessionFingerprint: Date.now(),
      application: config.application,
    };

    this.setIdentifier();
    this.setSuperProperties();
    this.subscribeToLaunchDarkly();
    this._initialized = true;
  }

  private subscribeToLaunchDarkly(): void {
    this.launchdarkly
      .variation$(FeatureFlagNames.mixpanelEventsWithExperimentData, false)
      .pipe(filter(enabled => enabled))
      .subscribe(() => {
        const experiments = FeatureFlagWhitelist.map(flag =>
          this.experiment(flag),
        ).filter(experiment => experiment.detail.reason.kind !== 'OFF');
        this.instanceProperties = {
          ...this.instanceProperties,
          experiments,
        };
        this.setSuperProperties();
      });
  }

  private experiment(flag: FeatureFlagNames): LaunchDarklyExperiment {
    return {
      name: flag,
      detail: this.launchdarkly.variationDetail(flag),
    };
  }

  /**
   * Tracks an event.
   *
   * @param eventName The name of the event
   * @param properties A set of properties to include with the event you're sending
   *
   * @returns Boolean or Object, If the tracking request was successfully initiated/queued,
   * an object with the tracking payload sent to the API server is returned; otherwise false
   *
   * https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltrack
   */
  track(
    eventName: AnalyticsEvent | string,
    properties?: Partial<TrackEventProperties>,
  ): void {
    this.patientId$.subscribe(patientId => {
      return this.mixpanelAPI.track(
        eventName,
        snakeCase({
          patientId,
          ...properties,
        }),
      );
    });
  }

  /**
   * Tracks external link click event.
   *
   * @param eventName The name of the event
   * @param properties A set of properties to include with the event you're sending
   * @param openLink the method to be called when mixpanel returns successfully that event has been tracked ie window open method
   * @returns void
   *
   */
  track_external_link(
    eventName: AnalyticsEvent | string,
    properties: Partial<TrackEventProperties>,
    openLink: Function,
  ): void {
    this.patientId$.subscribe(patientId => {
      return this.mixpanelAPI.track(
        eventName,
        snakeCase({
          patientId,
          ...properties,
        }),
        openLink(),
      );
    });
  }
}
