<div omgTheme>
  <div class="scrollable">
    <header>
      @if (isBannerVisible()) {
        <omg-privacy-banner></omg-privacy-banner>
      }
      <omg-app-navbar></omg-app-navbar>
    </header>
    <router-outlet></router-outlet>
  </div>
</div>
