import { HttpErrorResponse } from '@angular/common/http';
import { httpStatus } from '@app/utils';
import { environment } from '@environments/environment';

export const enum OMACS_ERROR_TYPE {
  BLOCKED = 'https://onemedical.atlassian.net/wiki/spaces/CIA/pages/912883715/OMACS+Error+Types#OMACS%3A-Blocked',
  UNAUTHORIZED = 'https://onemedical.atlassian.net/wiki/spaces/CIA/pages/912883715/OMACS+Error+Types#OMACS%3A-Unauthorized',
}

export const isOmacsError = (errorResponse: HttpErrorResponse): boolean => {
  if (errorResponse?.status !== httpStatus.FORBIDDEN) {
    return false;
  }
  switch (errorResponse?.error?.type) {
    case OMACS_ERROR_TYPE.BLOCKED:
      return true;
    default:
      return false;
  }
};

export const buildOmacsErrorDestinationUrl = (
  errorResponse: HttpErrorResponse,
): string => {
  const errorType = errorResponse?.error?.type;
  switch (errorType) {
    case OMACS_ERROR_TYPE.BLOCKED:
      return `${environment.memberManagementUi.host}/members/restricted/access_blocked`;
    default:
      throw new Error(`Unrecognized OMACS error type: ${errorType}`);
  }
};
