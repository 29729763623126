import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { map, Observable, take } from 'rxjs';

import { AnalyticsService } from '@app/core/analytics';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';

import {
  DocumentSummaryClientAppName,
  DocumentSummaryDataFieldName,
} from '../../../../../graphql/onelife.type';
import {
  AddDocumentFeedbackMutationService,
  GetDocumentSummaryQueryService,
} from '../../shared/document-summary.onelife.generated';
import { DocumentSummary } from '../../shared/document.type';
import { SummaryExcludeListByNoteType } from '../../shared/summary-exclude-list';
import { UnavailableSummary } from '../../shared/unavailable-summary';
import { DocumentSummaryFeedbackEvent } from '../document-summary-feedback/document-summary-feedback.component';

@Component({
  selector: 'omg-document-summary',
  templateUrl: './document-summary.component.html',
  styleUrls: ['./document-summary.component.scss'],
})
export class DocumentSummaryComponent implements OnInit {
  @Input() documentId: number;
  @Input() noteTypeName: string;
  documentSummarizationVisible: boolean;
  summary$: Observable<DocumentSummary>;
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private launchDarklyService: LaunchDarklyService,
    private documentSummaryService: GetDocumentSummaryQueryService,
    private documentFeedbackService: AddDocumentFeedbackMutationService,
    private analytics: AnalyticsService,
  ) {}

  ngOnInit(): void {
    this.documentSummarizationVisible = this.showDocumentSummary();
    this.initSummary();
  }

  initSummary(): void {
    this.summary$ = this.documentSummaryService
      .fetch({ documentId: this.documentId.toString() })
      .pipe(map(result => result.data.document.summary || UnavailableSummary));
  }

  showDocumentSummary(): boolean {
    return (
      this.launchDarklyService.variation(
        FeatureFlagNames.chartDocumentSummarizationVisible,
        false,
      ) && this.noteTypeAllowed()
    );
  }

  noteTypeAllowed(): boolean {
    return !SummaryExcludeListByNoteType.includes(this.noteTypeName);
  }

  onFeedbackSubmitted(event: DocumentSummaryFeedbackEvent): void {
    this.documentFeedbackService
      .mutate({
        input: {
          feedback: {
            documentSummaryId: event.summaryId,
            metadata: {
              url: window.location.href,
              clientAppName: DocumentSummaryClientAppName.PatientChart,
            },
            displayedSummaryDataFields: [
              DocumentSummaryDataFieldName.DetailedSummary,
            ],
            feedbackFields: event.fields,
          },
        },
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  onViewerCollapseToggle(expanded: boolean): void {
    if (expanded !== null) {
      this.summary$.pipe(take(1)).subscribe(summary => {
        const event = expanded
          ? AnalyticsEvent.DocumentSummaryExpanded
          : AnalyticsEvent.DocumentSummaryCollapsed;
        this.analytics.track(event, {
          ...DefaultAnalyticsProps,
          component: 'Document Summary',
          subcomponent: 'Document Summary Viewer',
          documentId: this.documentId,
          summaryId: summary.id,
        });
      });
    }
  }
}
