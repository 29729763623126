<div *ngIf="verificationState$ | ngrxPush as verificationState">
  <div class="om-list" om-layout="vertical">
    <div class="om-item"></div>
    <div class="om-item" *ngIf="cartStatus.requireTwoFactorCheckout">
      <div class="banner -info">{{ messages.twoFactorAgreement }}</div>
    </div>

    <div *ngIf="cartStatus.requireTwoFactorCheckout" om-layout-gutter class="padding-normal padding-top-none">
      <omg-checkbox
        label="Ready to sign"
        plainLabel="true"
        [(ngModel)]="readyToSignAttested"
        required="true"
        (click)="onReadyToSign()"
        data-cy="new-rx-ready-to-sign-checkbox"
      ></omg-checkbox>
    </div>

    <div class="padding-normal-horizontal" om-layout-gutter>
      <div *ngIf="!verificationState.passwordVerified" om-flex="45" om-layout="vertical">
        <label om-layout="vertical">
          Password
          <input
            omgInputText
            type="text"
            class="mask-input -with-error"
            autofocus="true"
            [ngClass]="{ 'ng-invalid': verificationState.errors?.invalidPassword || cartErrors.invalidPassword }"
            [formControl]="verificationForm.controls.get('password')"
            (keydown.enter)="onSend($event, verificationState.status)"
            data-cy="new-rx-provider-password-input"
          />
        </label>
        <span class="alert" *ngIf="verificationState.errors?.invalidPassword || cartErrors.invalidPassword">
          {{ verificationState.errors?.exceptions?.join(' ') || cartErrors.invalidPassword }}
        </span>
      </div>

      <div *ngIf="cartStatus.requireTwoFactorCheckout" om-layout om-layout-gutter-large>
        <ng-container *ngIf="verificationState.devices; else loadingMFADevices">
          <ng-container *ngIf="verificationState.manualDeviceSelectionRequired">
            <label om-flex="55" om-layout="vertical">
              2 Step Verification
              <omg-dropdown
                [flex]="true"
                placeholder="Two-factor Device Required"
                [options]="verificationForm.deviceOptions"
                [formControl]="verificationForm.controls.get('device')"
                data-cy="new-rx-provider-2fa-device-input"
              ></omg-dropdown>
            </label>
            <div *ngIf="verificationForm.codeEntryRequired" om-flex="33" om-layout="vertical">
              <label om-layout="vertical">
                Code
                <input
                  omgInputText
                  type="text"
                  class="-with-error"
                  omgFocusOn="medCheckoutFocus-token"
                  [ngClass]="{ 'ng-invalid': !!verificationState.errors?.invalidCode }"
                  [formControl]="verificationForm.controls.get('code')"
                  (keydown.enter)="onSend($event, verificationState.status)"
                  data-cy="new-rx-2fa-code-input"
                />
              </label>
              <span *ngIf="verificationState.errors?.invalidCode" class="alert">{{ messages.invalidCode }}</span>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #loadingMFADevices>
          <omg-feature-loading-spinner></omg-feature-loading-spinner>
        </ng-template>
      </div>
      <div om-layout="vertical" om-layout-gutter>
        <div *ngIf="verificationState.status.polling" om-layout-gutter>
          <i class="fa fa-lg fa-spinner fa-pulse"></i>
          <span>{{ messages.approvePush }}</span>
        </div>
        <span *ngIf="verificationState.errors?.pushFailed" class="alert">{{ messages.pushFailed }}</span>
        <span *ngIf="verificationState.status.complete && !(cartErrors.validationError || cartErrors.hasUnknownError)">
          {{ messages.signatureConfirmed }}
        </span>
      </div>
      <div *ngIf="enableWarningsDisplay">
        <div *ngIf="cartStatus.requireTwoFactorCheckout && !verificationState.devices?.length" class="banner -error">
          {{ messages.deviceListEmpty }}
        </div>
        <div *ngIf="!cartStatus.allHandwrittenItemsConfirmed" class="banner -error">
          {{ messages.mustConfirmHandwritten }}
        </div>
        <div *ngIf="cartErrors.validationError || cartErrors.hasUnknownError" class="banner -error -flush">
          {{ cartErrors.validationError || messages.unknownError }}
        </div>
      </div>
    </div>
    <omg-action-bar #actionBar>
      <ng-container left-buttons>
        <div om-layout-gutter-large>
          <button
            *ngIf="!(verificationState.status.complete && (cartErrors.validationError || cartErrors.hasUnknownError))"
            omgButton
            type="button"
            variant="primary"
            [disabled]="checkoutDisabled(verificationState.status)"
            (click)="onSend($event, verificationState.status)"
            data-cy="rx-verification-btn"
          >
            {{ buttonText }}
          </button>
          <button omgButton variant="link" (click)="onCancel()" data-cy="rx-verification-cancel-btn">Cancel</button>
        </div>
      </ng-container>
    </omg-action-bar>
  </div>
</div>
