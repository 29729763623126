import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, take } from 'rxjs';

import { windowToken as WINDOW } from '@app/shared/window/window.service';

import { ZendeskTokenResponse } from './zendesk-token-response';

/**
 * Zendesk Web Widget Classic Integration
 * @see https://developer.zendesk.com/documentation/classic-web-widget-sdks/
 */
@Injectable({
  providedIn: 'root',
})
export class ZendeskService {
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private apollo: Apollo,
  ) {}

  injectScript(): void {
    const script = this.document.createElement('script');
    script.id = 'ze-snippet';
    script.src =
      'https://static.zdassets.com/ekr/snippet.js?key=36992a6c-1f30-43f4-b3f3-7b11c6293b28';
    this.document.head.appendChild(script);
  }

  authenticate(): void {
    this.apollo
      .use('onelife')
      .query<ZendeskTokenResponse>({
        query: gql`
          query ZendeskToken {
            zendeskToken
          }
        `,
      })
      .pipe(
        take(1),
        map(response => response.data.zendeskToken),
      )
      .subscribe(token => {
        this.window.zESettings = {
          webWidget: {
            authenticate: {
              jwtFn: callback => {
                callback(token);
              },
            },
          },
        };
        // if this called before zendesk is ready, then zendesk will invoke the callback
        // specified on the zESettings.
        // if this is called after zendesk is ready, then zE function will exist on the window
        // and we tell zendesk to reauthenticate using the token specified in zESettings.

        // disabling to mute error while we resolve in pendo config
        /*if (this.window.zE) {
          this.window.zE('webWidget', 'helpCenter:reauthenticate');
        }*/
      });
  }
}
