import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';

import { DenialReason } from '@app/features/renewals/shared/renewals.type';
import { DialogService } from '@app/shared';
import { ActionBarComponent } from '@app/shared/components/action-bar/action-bar.component';

import { ChangeRxForm } from './../../shared/change-rx-form';
import { ChangeRxApprovalDialogComponent } from '../change-rx-approval-dialog/change-rx-approval-dialog.component';

@Component({
  selector: 'omg-change-item-action-bar',
  templateUrl: './change-item-action-bar.component.html',
  styleUrls: ['./change-item-action-bar.component.scss'],
})
export class ChangeItemActionBarComponent {
  @Input() form: ChangeRxForm;

  @ViewChild(ActionBarComponent, { static: true })
  actionBar: ActionBarComponent;

  checkoutAttempted: boolean;

  constructor(
    private dialogService: DialogService,
    private viewContainerRef: ViewContainerRef,
  ) {}

  get disabledApprovalAction(): boolean {
    return !this.form || this.form.model.saving || this.form.controls.invalid;
  }

  get disabledDenialAction(): boolean {
    return !this.form || this.form.model.saving;
  }

  openApprovalModal(isApproved: boolean, reasonForDenial?: DenialReason): void {
    if (isApproved && this.disabledApprovalAction) {
      this.checkoutAttempted = true;
      return this.form.controls.markAsDirty();
    }

    this.dialogService.open(ChangeRxApprovalDialogComponent, {
      viewContainerRef: this.viewContainerRef,
      autoFocus: true,
      disableClose: true,
      data: {
        form: this.form,
        isApproved,
        reasonForDenial,
      },
    });
  }

  openDenyDrawer(): void {
    this.checkoutAttempted = false;
    this.actionBar.showConfirmDrawer();
  }

  closeDenyDrawer(): void {
    this.actionBar.hideConfirmDrawer();
  }
}
