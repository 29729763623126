import {
  computed,
  DestroyRef,
  effect,
  inject,
  Injectable,
  Injector,
  runInInjectionContext,
  Signal,
  signal,
} from '@angular/core';
import { lastValueFrom, of } from 'rxjs';
import { GetAppointmentRecordingQueryService } from '@app/features/healthscribe/graphql/get-appointment-recording.onelife.generated';
import {
  GetRecordingBasicsQuery,
  GetRecordingBasicsQueryService,
  GetRecordingBasicsQueryVariables,
  GetRecordingInsightsQuery,
  GetRecordingInsightsQueryService,
  GetRecordingInsightsQueryVariables,
  GetRecordingLlmInsightsQuery,
  GetRecordingLlmInsightsQueryService,
  GetRecordingLlmInsightsQueryVariables,
  HealthScribeSettingsFragment,
} from '@app/features/summaries/components/summaries/get-recordings.onelife.generated';
import { Summary } from '../../shared/summaries.type';
import { QueryRef } from 'apollo-angular';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { PatientSelectors } from '@app/core';
import { catchError } from 'rxjs/operators';
import { AppointmentRecordingState } from '../../../../../graphql/onelife.type';

interface NestedSignal<T> {
  signal: Signal<T | undefined>;
  setSignal: (signalToSet: Signal<T>) => void;
}

function nestedSignal<T>(): NestedSignal<T> {
  const signalOfSignal = signal<Signal<T | undefined>>(
    signal<T | undefined>(undefined),
  );

  return {
    signal: computed(() => signalOfSignal()()) as Signal<T | undefined>,
    setSignal: (signalToSet: Signal<T>): void => {
      signalOfSignal.set(signalToSet);
    },
  };
}

/**
 * This service handles the initial fetching of an Appointment and the subsequent polling
 * of an associated AppointmentRecording, until we have a recording in the 'transcribed' state.
 */
@Injectable({
  providedIn: 'root',
})
export class AppointmentRecordingQueryService {
  appointmentRecordingIsLoading = signal(true);
  llmInsightsAreLoading = signal(true);
  #llmInsightsNestedSignal =
    nestedSignal<GetRecordingLlmInsightsQuery['appointmentRecording']>();
  #transcriptNestedSignal =
    nestedSignal<GetRecordingInsightsQuery['appointmentRecording']>();
  #recordingBasicsNestedSignal =
    nestedSignal<GetRecordingBasicsQuery['appointmentRecording']>();
  llmInsights = this.#llmInsightsNestedSignal.signal;
  appointmentRecordingBasics = this.#recordingBasicsNestedSignal.signal;
  transcriptAppointmentRecording = this.#transcriptNestedSignal.signal;
  #settingsNestedSignal = nestedSignal<HealthScribeSettingsFragment>();
  healthScribeSettings = this.#settingsNestedSignal.signal;
  private appointmentRecordingId = signal<string | null>(null);

  getAppointmentRecordingQueryService = inject(
    GetAppointmentRecordingQueryService,
  );
  getRecordingBasicsQueryService = inject(GetRecordingBasicsQueryService);
  getRecordingInsightsQueryService = inject(GetRecordingInsightsQueryService);
  getRecordingLlmInisghtsQueryService = inject(
    GetRecordingLlmInsightsQueryService,
  );
  private patientSelectors = inject(PatientSelectors);
  private injector = inject(Injector);
  private destroyRef = inject(DestroyRef);

  constructor() {
    const patientId = toSignal(this.patientSelectors.patientId);
    /**
     * WHEN appointmentID is updated, update the existing polling QueryRef with the new appointmentId
     * IF there was no existing poller set up, create a new poller and set the signal to emit the value when it changes
     */
    effect(onCleanup => {
      const appointmentId = this.appointmentRecordingId();

      if (appointmentId) {
        if (this.appointmentRecordingBasicsQueryRef) {
          this.appointmentRecordingBasicsQueryRef
            .setVariables({
              id: appointmentId,
            })
            .then(() => this.appointmentRecordingIsLoading.set(false));
        } else {
          this.appointmentRecordingBasicsQueryRef =
            this.getRecordingBasicsQueryService.watch(
              {
                id: appointmentId,
              },
              { fetchPolicy: 'network-only' },
            );
          const subscription =
            this.appointmentRecordingBasicsQueryRef.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(result => {
                this.appointmentRecordingIsLoading.set(result.loading);
              });

          onCleanup(() => {
            subscription.unsubscribe();
          });
          queueMicrotask(() => {
            runInInjectionContext(this.injector, () => {
              const result = toSignal(
                this.appointmentRecordingBasicsQueryRef!.valueChanges,
              );
              this.#recordingBasicsNestedSignal.setSignal(
                computed(() => result()?.data?.appointmentRecording),
              );
            });
          });
        }
      }
    });

    // Once the polling has stopped / the state has moved to transcribed, grab the insights
    effect(
      onCleanup => {
        const appointmentId = this.appointmentRecordingId();
        const state = this.appointmentRecordingBasics()?.state;

        if (appointmentId && state === AppointmentRecordingState.Transcribed) {
          this.appointmentRecordingQueryRef =
            this.getRecordingInsightsQueryService.watch(
              {
                id: appointmentId,
              },
              { fetchPolicy: 'network-only' },
            );
          const insightsSubscription =
            this.appointmentRecordingQueryRef.valueChanges
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(result => {
                this.appointmentRecordingIsLoading.set(result.loading);
              });
          this.llmInsightsQueryRef =
            this.getRecordingLlmInisghtsQueryService.watch({
              id: appointmentId,
              patientId: patientId()?.toString(),
            });
          const llmInsightsSubscription = this.llmInsightsQueryRef.valueChanges
            .pipe(
              takeUntilDestroyed(this.destroyRef),
              catchError(err => {
                this.llmInsightsAreLoading.set(false);
                // Not sure what we want to return here or how / if we want
                // to surface the error in the ui
                return of();
              }),
            )
            .subscribe(result => {
              this.llmInsightsAreLoading.set(result.loading);
            });

          onCleanup(() => {
            insightsSubscription.unsubscribe();
            llmInsightsSubscription.unsubscribe();
          });
          queueMicrotask(() => {
            runInInjectionContext(this.injector, () => {
              const result = toSignal(
                this.appointmentRecordingQueryRef!.valueChanges,
              );
              this.#transcriptNestedSignal.setSignal(
                computed(() => result()?.data?.appointmentRecording),
              );
              this.#settingsNestedSignal.setSignal(
                computed(
                  () =>
                    result()?.data?.internalUser
                      ?.settings as HealthScribeSettingsFragment,
                ),
              );
              const llmResult = toSignal(
                this.llmInsightsQueryRef!.valueChanges,
                { rejectErrors: true },
              );
              this.#llmInsightsNestedSignal.setSignal(
                computed(() => llmResult()?.data?.appointmentRecording),
              );
            });
          });
        }
      },
      {
        allowSignalWrites: true,
      },
    );

    /**
     * WHEN the recording state is _transcribing_, begin polling for changes
     * this is triggered after the initial recording is fetched, and we only
     * know if we need to poll based on its state.
     */
    effect(onCleanup => {
      const recording = this.appointmentRecordingBasics();
      const appointmentId = this.appointmentRecordingId();

      if (
        recording?.state &&
        ['transcribing', 'recording'].includes(recording?.state) &&
        appointmentId !== null &&
        recording?.id === appointmentId
      ) {
        this.appointmentRecordingBasicsQueryRef?.startPolling(
          this.transcribingStatePollIntervalMs,
        );

        onCleanup(() => {
          this.appointmentRecordingBasicsQueryRef?.stopPolling();
        });
      }
    });
  }

  setAppointmentRecordingId(appointmentRecordingId: string | null): void {
    this.appointmentRecordingId.set(appointmentRecordingId);
  }

  /**
   * Resets the appointmentId that the service is polling for information about.
   */
  clearAppointmentRecordingId(): void {
    this.appointmentRecordingId.set(null);
  }

  /**
   * Initial call that allows us to take a summary and fetch the associated appointment,
   * then appointmentrecording.
   * @param summary The Summary that we're attempting to gather healthscribe information about
   * @returns
   */
  async fetchAppointmentRecording(summary: Summary): Promise<void> {
    if (!summary.appointment) return;

    this.appointmentRecordingIsLoading.set(true);
    const recording = await lastValueFrom(
      this.getAppointmentRecordingQueryService.fetch(
        {
          appointmentId: summary.appointment.id.toString(),
        },
        { fetchPolicy: 'network-only' },
      ),
    );

    if (!recording.data?.appointment?.recording?.id) {
      this.appointmentRecordingIsLoading.set(false);
    }

    if (
      recording?.data?.appointment?.recording?.id ===
      this.appointmentRecordingId()
    ) {
      this.appointmentRecordingIsLoading.set(false);
    }

    this.appointmentRecordingId.set(
      recording.data?.appointment?.recording?.id ?? null,
    );
  }

  private readonly transcribingStatePollIntervalMs: number = 10000;
  private appointmentRecordingQueryRef:
    | QueryRef<GetRecordingInsightsQuery, GetRecordingInsightsQueryVariables>
    | undefined;
  private appointmentRecordingBasicsQueryRef:
    | QueryRef<GetRecordingBasicsQuery, GetRecordingBasicsQueryVariables>
    | undefined;
  private llmInsightsQueryRef:
    | QueryRef<
        GetRecordingLlmInsightsQuery,
        GetRecordingLlmInsightsQueryVariables
      >
    | undefined;
}
