import {
  Component,
  computed,
  Input,
  OnInit,
  Signal,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngrx/store';

import { AppState } from '@app/core/store/store/app-store.reducer';
import { isNotNullOrUndefined } from '@app/utils';

import {
  getProgramVisitBySummary,
  updateProgramVisit,
} from './../../store/program-visit.actions';
import { ProgramVisitSelectors } from './../../store/program-visit.selectors';
import { ProgramVisit } from '../../shared/program-visit.type';
import { ChronicCareManagementFormComponent } from '../chronic-care-management-form/chronic-care-management-form.component';
import { toSignal } from '@angular/core/rxjs-interop';
@Component({
  selector: 'omg-chronic-care-management',
  templateUrl: './chronic-care-management.component.html',
})
export class ChronicCareManagementComponent implements OnInit {
  @Input() summaryId: number;
  @Input() completed: boolean;

  @ViewChild(ChronicCareManagementFormComponent)
  chronicCareForm: ChronicCareManagementFormComponent;

  programVisit: Signal<ProgramVisit | undefined> = toSignal(
    this.programVisitSelectors.get().pipe(isNotNullOrUndefined<ProgramVisit>()),
  );
  loadingProgramVisit: Signal<boolean> = toSignal(
    this.programVisitSelectors.loading(getProgramVisitBySummary),
    { initialValue: true },
  );
  loadingUpdateProgramVisit: Signal<boolean> = toSignal(
    this.programVisitSelectors.loading(updateProgramVisit),
    { initialValue: true },
  );
  saveIsDisabled: Signal<boolean> = computed(() => {
    return (
      this.loadingProgramVisit() ||
      this.loadingUpdateProgramVisit() ||
      !this.chronicCareForm?.chronicCareFormValid
    );
  });

  constructor(
    private programVisitSelectors: ProgramVisitSelectors,
    private store: Store<AppState>,
  ) {}

  ngOnInit() {
    this.store.dispatch(
      getProgramVisitBySummary({ summaryId: this.summaryId }),
    );
  }

  get chronicCareFormValid(): boolean {
    return this.chronicCareForm.chronicCareFormValid;
  }

  markChronicCareFormTouched() {
    if (this.chronicCareForm) {
      this.chronicCareForm.markFormAsTouched();
    }
  }
}
