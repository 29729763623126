import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { buildCheckoutMessages } from '@app/modules/rx-cart/shared/rx-cart-utils';
import { filterTruthy } from '@app/utils';

import { RxVerificationForm } from '../../shared/rx-verification-form';
import { RxVerificationFormService } from '../../shared/rx-verification-form.service';
import {
  mapToButtonText,
  readyToVerify,
} from '../../shared/rx-verification-utils';
import {
  RxCartErrors,
  RxCartStatus,
  RxCartTypes,
  RxVerification,
  RxVerificationStatus,
  RxVerified,
} from '../../shared/rx-verification.type';
import { RxVerificationActions } from '../../store/rx-verification.actions';
import { RxVerificationSelectors } from '../../store/rx-verification.selectors';

@Component({
  selector: 'omg-rx-verification',
  templateUrl: './rx-verification.component.html',
  styleUrls: ['./rx-verification.component.scss'],
})
export class RxVerificationComponent implements OnInit, OnDestroy {
  @Input() cartId: number;
  @Input() cartType: RxCartTypes;
  @Input() cartStatus: RxCartStatus;
  @Input() cartErrors: RxCartErrors;

  @Output() completeVerification = new EventEmitter<RxVerified>();
  @Output() cancel = new EventEmitter();

  messages = buildCheckoutMessages();
  verificationState$: Observable<RxVerification>;
  buttonText: String;
  verificationForm: RxVerificationForm;
  enableWarningsDisplay: boolean;
  tokenListener: Subscription;

  readyToSignAttested: boolean;

  constructor(
    private rxVerificationActions: RxVerificationActions,
    private rxVerificationSelectors: RxVerificationSelectors,
    private rxVerificationFormService: RxVerificationFormService,
  ) {}

  ngOnDestroy(): void {
    this.rxVerificationActions.unsubscribeFromNotifications();
    this.verificationForm.unsubscribe();
    this.tokenListener.unsubscribe();
  }

  ngOnInit(): void {
    this.rxVerificationActions.initializeState();

    this.readyToSignAttested = !this.cartStatus.requireTwoFactorCheckout;

    if (this.cartStatus.requireTwoFactorCheckout) {
      this.rxVerificationActions.loadDevices(this.cartType);
    }
    this.verificationForm = this.rxVerificationFormService.buildForm();

    this.verificationState$ =
      this.rxVerificationSelectors.verificationState.pipe(
        tap(
          state => (this.buttonText = mapToButtonText(state, this.cartStatus)),
        ),
      );

    // verification is complete when token is received
    this.tokenListener = this.rxVerificationSelectors.token
      .pipe(filterTruthy(), take(1))
      .subscribe(token => this.onComplete(token));
  }

  checkoutDisabled(status: RxVerificationStatus): boolean {
    return (
      status.sending ||
      status.polling ||
      status.complete ||
      !this.readyToSignAttested ||
      !readyToVerify(this.cartStatus)
    );
  }

  onSend($event: Event, status: RxVerificationStatus): void {
    $event.preventDefault();
    this.enableWarningsDisplay = true;

    if (!this.checkoutDisabled(status)) {
      this.verifyPassword();
    }
  }

  onCancel(): void {
    this.cancel.emit();
  }

  onReadyToSign(): void {
    if (this.readyToSignAttested) {
      this.rxVerificationActions.markReadyToSign(this.cartId);
    }
  }

  private verifyPassword(): void {
    return this.cartStatus.requireTwoFactorCheckout
      ? this.rxVerificationActions.verify({
          cartId: this.cartId,
          cartType: this.cartType,
          password: this.verificationForm.password,
          device: this.verificationForm.device,
          code: this.verificationForm.code,
        })
      : this.onComplete(); // password will be verified on cart submission
  }

  private onComplete(token?: string): void {
    const { password } = this.verificationForm;
    this.completeVerification.emit({ password, token });
  }
}
