import { Component } from '@angular/core';
import { FeatureFlagNames, LaunchDarklyService } from '@app/core';
import { PrivacyBannerService } from '@app/features/omacs/shared/privacy-banner.service';

@Component({
  selector: 'omg-app-shell',
  templateUrl: 'app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent {
  private isBannerEnabled = false;
  constructor(
    private privacyBannerService: PrivacyBannerService,
    private launchDarklyService: LaunchDarklyService,
  ) {
    this.isBannerEnabled = this.launchDarklyService.variation(
      FeatureFlagNames.showPrivacyBanner,
      false,
    );
  }

  protected isBannerVisible(): boolean {
    return this.isBannerEnabled && this.privacyBannerService.isVisible();
  }
}
