import { addMonths, addWeeks, addYears } from '@app/utils/dates';

export const today = new Date();
const twoWeeks = new Date(addWeeks(today, 2));
const oneMonth = new Date(addMonths(today, 1));
const threeMonths = new Date(addMonths(today, 3));
const sixMonths = new Date(addMonths(today, 6));
const oneYear = new Date(addYears(today, 1));

export const defaultQuickDateOptions = [
  {
    label: 'Today',
    value: today,
  },
  {
    label: '2 Weeks',
    value: twoWeeks,
  },
  {
    label: '1 Month',
    value: oneMonth,
  },
  {
    label: '3 Months',
    value: threeMonths,
  },
  {
    label: '6 Months',
    value: sixMonths,
  },
  {
    label: '1 Year',
    value: oneYear,
  },
];

defaultQuickDateOptions.forEach(option => option.value.setHours(0, 0, 0, 0));
