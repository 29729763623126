import { Component, inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
  first,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { AnalyticsService } from '@app/core/analytics/analytics.service';
import { AnalyticsEvent } from '@app/core/analytics/analytics.type';
import { DefaultAnalyticsProps } from '@app/core/analytics/default-analytics-props';
import { Commentable } from '@app/modules/comments/shared/comments.type';
import { Note } from '@app/modules/note/shared/note.type';
import { ReferenceDataKeys } from '@app/modules/reference-data/shared/reference-data.type';
import { ReferenceDataSelectors } from '@app/modules/reference-data/store/reference-data.selectors';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { DropdownItem, FormModel } from '@app/shared';

import { mapLetterTemplatesToDropdownItems } from '../../shared/letter-mapper';
import { UserInfo } from '../../shared/letter.type';
import { NoteActions, NoteSelectors } from '../../store';
import { RealUserMonitoring } from '@app/core/monitoring';

@Component({
  selector: 'omg-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss'],
})
export class LetterComponent implements OnInit {
  @Input() letter$: Observable<Note>;
  @Input() todo$: Observable<Todo>;
  @Input() letterHasIncompleteTodo: boolean;
  @Input() letterFormModel: FormModel;
  @Input() userInfo: UserInfo;
  @Input() commentsCollapseRef: any;
  @Input() loadingPDF = false;

  letterTemplatesDropdown$: Observable<DropdownItem[]>;
  commentable$: Observable<Commentable>;
  letterId$: Observable<number | undefined>;

  private rum = inject(RealUserMonitoring);
  private referenceDataSelectors = inject(ReferenceDataSelectors);
  private noteSelectors = inject(NoteSelectors);
  private noteActions = inject(NoteActions);
  private analytics = inject(AnalyticsService);

  ngOnInit(): void {
    this.initObservables();
  }

  private initObservables(): void {
    this.letterTemplatesDropdown$ = this.referenceDataSelectors
      .get(ReferenceDataKeys.letterTemplates)
      .pipe(map(mapLetterTemplatesToDropdownItems));
    this.commentable$ = this.letter$.pipe(
      map(letter => ({
        id: letter.id,
        totalComments: letter.totalComments,
        commentableType: 'letter',
      })),
    );
    this.letterId$ = this.letter$.pipe(map(letter => letter.id));
  }

  onCommentUpdate(type: 'add' | 'remove'): void {
    this.letter$.pipe(take(1)).subscribe(letter => {
      if (type === 'add') {
        this.noteActions.addNoteComment(letter);
      } else {
        this.noteActions.removeNoteComment(letter);
      }
    });
  }

  applyTemplate(template: DropdownItem): void {
    if (template) {
      this.letter$
        .pipe(
          take(1),
          tap(letter => {
            if (letter.id) {
              this.noteActions.loadTemplate(template.value, letter.id);
            }
          }),
          switchMap(() => this.noteSelectors.isPending),
          first(pending => !pending),
          withLatestFrom(this.letter$),
          map(([_, letter]: [boolean, Note]) => letter),
        )
        .subscribe(letter => {
          this.letterFormModel.patchValue(letter);
          this.trackTemplateLoad(letter.id);
        });
    }
  }

  private trackTemplateLoad(letterId?: number): void {
    this.rum.recordEvent('com.onemedical.ui.letter_template_loaded', {
      component: 'Letter',
    });
    this.analytics.track(AnalyticsEvent.FieldSelected, {
      ...DefaultAnalyticsProps,
      component: 'Official Letter',
      subcomponent: 'Apply Template',
      noteId: letterId,
    });
  }
}
