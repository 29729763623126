import { effect, inject, Injectable, signal, Signal } from '@angular/core';
import { AudioRecorderService } from './audio-recorder.service';
import { HealthscribeRecordingUploaderService } from './healthscribe-recording-uploader.service';
import { StopwatchSignalService } from '@app/features/healthscribe/recording/stopwatchSignal';
import { windowToken } from '@app/shared/window/window.service';
import * as Sentry from '@sentry/angular-ivy';
import { get } from 'lodash';
import { AppointmentRecording } from '../../../../graphql/onelife.type';

export enum RECORDING_STATE {
  NONE = 'none',
  STARTING = 'starting',
  STARTING_STREAM = 'starting_stream',
  IN_PROGRESS = 'in_progress',
  PAUSED = 'paused',
  ENDED = 'ended',
  ERROR = 'error',
}

@Injectable({
  providedIn: 'root',
})
export class HealthscribeRecorderService {
  private window = inject<Window>(windowToken);

  constructor(
    private audioRecorder: AudioRecorderService,
    private healthscribeRecordingUploader: HealthscribeRecordingUploaderService,
  ) {
    audioRecorder.chunks$.subscribe(blob => this.onChunk(blob));

    effect(onCleanup => {
      if (
        [RECORDING_STATE.IN_PROGRESS, RECORDING_STATE.PAUSED].includes(
          this.recordingState(),
        )
      ) {
        const handler = (e: BeforeUnloadEvent): void => {
          e.preventDefault();
        };
        this.window.addEventListener('beforeunload', handler);

        onCleanup(() => {
          this.window.removeEventListener('beforeunload', handler);
        });
      }
    });
  }

  appointmentRecordingId: string | undefined;
  #stopwatch = inject(StopwatchSignalService).createStopwatchSignal();

  #recordingState = signal(RECORDING_STATE.NONE);

  get recordingState(): Signal<RECORDING_STATE> {
    return this.#recordingState;
  }

  /**
   * Duration of the current recording in MILLISECONDS! NOT SECONDS!
   */
  get currentDuration(): Signal<number> {
    return this.#stopwatch.timeElapsedMs;
  }

  async onChunk(blob: Blob): Promise<void> {
    if (!this.appointmentRecordingId)
      throw new Error(
        'Received a chunk when no appointment recording ID was defined!',
      );

    await this.healthscribeRecordingUploader.onChunk(
      this.appointmentRecordingId,
      blob,
    );
  }

  // TODO: When the recording state is ended, what should this do?
  async startRecording(
    appointmentId: string,
  ): Promise<Partial<AppointmentRecording> | null | void> {
    if (
      this.#recordingState() === RECORDING_STATE.NONE ||
      this.#recordingState() === RECORDING_STATE.ERROR
    ) {
      this.#recordingState.set(RECORDING_STATE.STARTING_STREAM);

      try {
        this.appointmentRecordingId =
          await this.healthscribeRecordingUploader.createRecording(
            appointmentId,
          );
      } catch (error) {
        this.setError(error);
        return;
      }

      if (!this.appointmentRecordingId) {
        this.setError(new Error('No appointment recording id'));
        return;
      }
      let appointmentRecording;
      try {
        const { data } = await this.startAppointmentRecording();
        appointmentRecording = get(
          data,
          'startStreamingSession.appointmentRecording',
          null,
        );
      } catch (error) {
        this.setError(error);
        return;
      }

      // TODO: What should happen when this throws an error?
      await this.audioRecorder.startRecording();
      this.#recordingState.set(RECORDING_STATE.IN_PROGRESS);
      this.#stopwatch.start();
      return appointmentRecording;
    } else if (this.#recordingState() === RECORDING_STATE.PAUSED) {
      await this.resumeRecording();
    }
  }

  async pauseRecording(desiredState: string): Promise<void> {
    if (this.#recordingState() === RECORDING_STATE.IN_PROGRESS) {
      if (!this.appointmentRecordingId)
        throw new Error('No appointment recording ID defined!');

      if (desiredState === RECORDING_STATE.PAUSED) {
        this.#recordingState.set(RECORDING_STATE.PAUSED);
        await this.healthscribeRecordingUploader.pauseStream(
          this.appointmentRecordingId,
        );
      }
      await this.audioRecorder.pauseRecording();
      this.#stopwatch.stop();
    }
  }

  async resumeRecording(): Promise<void> {
    if (this.#recordingState() === RECORDING_STATE.PAUSED) {
      this.#recordingState.set(RECORDING_STATE.IN_PROGRESS);
      if (!this.appointmentRecordingId)
        throw new Error('No appointment recording ID defined!');
      await this.healthscribeRecordingUploader.resumeStream(
        this.appointmentRecordingId,
      );
      await this.audioRecorder.resumeRecording();
      this.#stopwatch.start();
    }
  }

  async endRecording(): Promise<string> {
    this.#recordingState.set(RECORDING_STATE.ENDED);
    // TODO: Fix (possible) race condition: what happens when the audio recorder stops, it starts
    // uploading final chunks, but `transcribeRecording` is called before that is finished? We don't wait
    // for that to finish here! (not relevant if we don't upload the entire recording on stop).
    await this.audioRecorder.stopRecording();
    this.#stopwatch.stop();
    if (!this.appointmentRecordingId)
      throw new Error('No appointment recording ID defined!');
    await this.healthscribeRecordingUploader.transcribeRecording(
      this.appointmentRecordingId,
    );
    this.#stopwatch.reset();
    const recordingId = this.appointmentRecordingId;
    this.appointmentRecordingId = undefined;
    this.#recordingState.set(RECORDING_STATE.NONE);
    return recordingId;
  }

  private startAppointmentRecording() {
    return this.healthscribeRecordingUploader.startStreamingSession(
      this.appointmentRecordingId!,
    );
  }

  private setError(error) {
    this.#recordingState.set(RECORDING_STATE.ERROR);
    Sentry.captureException(error);
  }
}
