import { computed, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PrivacyBannerService {
  private acknowledged = signal(true); // Set to true initially to prevent flickering

  readonly PRIVACY_BANNER_ACKNOWLED_KEY = 'privacyBannerAcknowledgedOn';
  readonly isVisible = computed(() => !this.acknowledged());

  constructor() {
    this.acknowledged.set(
      this.today() === localStorage.getItem(this.PRIVACY_BANNER_ACKNOWLED_KEY),
    );
  }

  acknowledge(): void {
    localStorage.setItem(this.PRIVACY_BANNER_ACKNOWLED_KEY, this.today());
    this.acknowledged.set(true);
  }

  private today(): string {
    return new Date().toISOString().split('T')[0];
  }
}
