<div
  omgCollapse
  [disabled]="disabled"
  [expanded]="expanded"
  class="collapsible-header"
  [class.collapsible-header-bg-grey]="colorVariant === 'grey'"
  (expandedChange)="expandedChange.emit($event)"
  (analyticsExpandedChange)="analyticsExpandedChange.emit($event)"
  #collapseRef="omgCollapse"
>
  <div
    om-layout
    omgCollapseToggle
    om-layout="vertical"
    [ngClass]="chevronClass"
  >
    <div class="collapse-toggle">
      <div class="header plain-text">
        <ng-content select="[header]" />
      </div>
      @if (!disabled) {
        <div class="header-chevron">
          <omg-collapsed disableAnimation="true">
            <i class="fa fa-chevron-down"></i>
          </omg-collapsed>
          <omg-expanded disableAnimation="true">
            <i class="fa fa-chevron-up"></i>
          </omg-expanded>
        </div>
      }
    </div>
  </div>
  <omg-expanded
    om-layout="vertical"
    class="message"
    [ngClass]="messageVariant"
  >
    <ng-content select="[message]" />
  </omg-expanded>
</div>
