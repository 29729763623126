// @ts-strict-ignore
import { getOr } from '@app/utils';

import {
  ProblemActionsResponse,
  ProblemAutoCreationsFlagSaveRequest,
  ProblemCodeLocationResponse,
  ProblemHistoryEditedByResponse,
  ProblemHistoryItemResponse,
  ProblemHistoryResponse,
  ProblemHistoryTypeResponse,
  ProblemResponse,
  ProblemSaveRequest,
  ProblemTypeResponse,
  ProblemUpdateRequest,
} from './problems-api.type';
import {
  problemCodeHighlight,
  problemTypeHighlight,
  setCustomProblemCode,
} from './problems-utils';
import {
  Problem,
  ProblemActions,
  ProblemCodeLocation,
  ProblemCustomCodeSelection,
  ProblemForm,
  ProblemHistory,
  ProblemHistoryEditedBy,
  ProblemHistoryItem,
  ProblemStatus,
  ProblemType,
} from './problems.type';

/**
 * Response Mappers
 */

/* istanbul ignore next */
const mapProblemTypeLocationResponseToEntity = (
  location: ProblemCodeLocationResponse,
): ProblemCodeLocation => ({
  id: location.id,
  name: location.name,
});

/* istanbul ignore next */
const mapProblemTypeResponseToEntity = (
  problemType: ProblemTypeResponse,
): ProblemType => {
  return {
    id: problemType.id,
    clinicalDescription: problemType.clinical_description,
    clinicalAbbreviation: null,
    layDescription: problemType.lay_description,
    importance: problemType.importance,
    locations:
      problemType.locations && problemType.locations.length > 0
        ? problemType.locations.map(mapProblemTypeLocationResponseToEntity)
        : null,
    decisionSupport: problemType.decision_support,
    autoCreations: [],
    tags: [],
  };
};

/* istanbul ignore next */
const mapProblemActionsResponseToEntity = (
  problemActions: ProblemActionsResponse,
): ProblemActions => {
  return {
    create: {
      createdFollowUpOrder: getOr(
        false,
        'create.created_follow_up_order',
        problemActions,
      ),
    },
    delete: {
      allowed: problemActions.delete.allowed,
      showWarning: problemActions.delete.show_warning,
      reason: problemActions.delete.reason,
    },
  };
};

/* istanbul ignore next */
export const mapProblemResponseToEntity = (
  response: ProblemResponse,
): Problem => {
  const problemType = response.problem_type || { locations: [] };
  const actions = response.actions || {
    delete: { allowed: null, show_warning: null, reason: null },
  };
  const problemCodeLocation = response.problem_code_location || {};
  let entity: Problem = {
    id: response.id,
    patientId: response.patient_id,
    problemHistoryId: response.problem_history_id,
    status: response.status,
    active: response.active,
    unconfirmed: response.status === ProblemStatus.UNCONFIRMED,
    suspected: response.status === ProblemStatus.SUSPECTED,
    code: response.code,
    briefComment: response.brief_comment,
    onset: response.onset,
    resolution: response.resolution,
    summary: response.summary,
    aAndP: response.a_and_p,
    aAndPDisplayText: response.a_and_p_display_text,
    editedBy: response.edited_by,
    updatedAt: response.updated_at,
    createdAt: response.created_at,
    originatingObject: response.originating_object,
    historyStartDate: response.history_start_date,
    problemType: mapProblemTypeResponseToEntity(problemType),
    problemCodeLocation:
      mapProblemTypeLocationResponseToEntity(problemCodeLocation),
    useCustomProblemType: response.use_custom_problem_type,
    includedInMedicalHistory: response.included_in_medical_history,
    actions: mapProblemActionsResponseToEntity(actions),
    customProblemTypeCode: response.custom_problem_type_code || null,
    customProblemTypeCodeDescription:
      response.custom_problem_type_code_description || null,
    customProblemTypeDescription:
      response.custom_problem_type_description || null,
    customProblemCodeSelection: null,
    notificationText: null,
    autoCreationsFlags: {},
    isMinorChange: response.is_minor_change,
    history: null,
    needsRefresh: response.needs_refresh,
    annotation: response.annotation,
    annotatedBy: response.annotated_by,
    annotatedAt: response.annotated_at,
  };

  if (entity.customProblemTypeCode && entity.customProblemTypeCodeDescription) {
    entity = setCustomProblemCode(entity);
  }

  return entity;
};

/* istanbul ignore next */
const mapProblemHistoryCodeLocationResponseToEntity = (
  codeLocation: ProblemCodeLocationResponse,
): ProblemCodeLocation => {
  return {
    id: codeLocation.id,
    name: codeLocation.name,
  };
};

/* istanbul ignore next */
const mapProblemHistoryEditedByResponseToEntity = (
  editedBy: ProblemHistoryEditedByResponse,
): ProblemHistoryEditedBy => {
  return {
    id: editedBy.id,
    name: editedBy.name,
  };
};

/* istanbul ignore next */
const mapProblemHistoryTypeResponseToEntity = (
  type: ProblemHistoryTypeResponse,
): ProblemType => {
  return <ProblemType>{
    clinicalDescription: type.clinical_description,
    id: type.id,
    importance: type.importance,
  };
};

/* istanbul ignore next */
const mapProblemHistoryItemsResponseToEntity = (
  problemItems: ProblemHistoryItemResponse[],
): ProblemHistoryItem[] => {
  return problemItems.map(item => {
    const problemCodeLocation = item.problem_code_location || {};
    const editedBy = item.edited_by || {};
    const problemType = item.problem_type || {};

    return {
      aAndP: item.a_and_p,
      briefComment: item.brief_comment,
      createdAt: item.created_at,
      editedBy: mapProblemHistoryEditedByResponseToEntity(editedBy),
      id: item.id,
      onset: item.onset,
      problemCodeLocation:
        mapProblemHistoryCodeLocationResponseToEntity(problemCodeLocation),
      problemType: mapProblemHistoryTypeResponseToEntity(problemType),
      resolution: item.resolution,
      status: item.status,
      annotation: item.annotation,
      annotatedAt: item.annotated_at,
      annotatedBy: item.annotated_by,
    };
  });
};

/* istanbul ignore next */
export const mapProblemHistoryResponseToEntity = (
  response: ProblemHistoryResponse,
  problemId: number,
): ProblemHistory => {
  return {
    items: mapProblemHistoryItemsResponseToEntity(response.items),
    total: response.total,
    problemId,
  };
};

/**
 * Save Request Mappers
 */

/* istanbul ignore next */
export const mapProblemToSaveRequest = (
  problem: ProblemForm,
): Partial<ProblemSaveRequest> => {
  return {
    a_and_p: problem.assessmentAndPlan,
    brief_comment: problem.briefComment,
    custom_problem_type_description: problem.customProblemDescription,
    custom_problem_type_code: problem.problemCode,
    custom_problem_type_code_description: problem.customProblemDescription,
    onset: problem.onset,
    problem_code_location: { id: problem.locationId },
    problem_type: { id: problem.problemId },
    resolution: problem.resolution,
    summary: problem.summary,
    included_in_medical_history: problem.includedInMedicalHistory,
    use_custom_problem_type: problem.isCustom,
    auto_creations_flags: <ProblemAutoCreationsFlagSaveRequest>{
      basic_follow_up_order: problem.basicFollowUpOrder,
      visit_follow_up_order: problem.visitFollowUpOrder,
    },
    status: problem.status,
    annotation: problem.annotation,
  };
};

/**
 * Update Request Mappers
 */

/* istanbul ignore next */
export const mapProblemToUpdateRequest = (
  problem: ProblemForm,
  isAutosave = false,
): ProblemUpdateRequest => {
  const requestPayload: ProblemUpdateRequest = {
    a_and_p: problem.assessmentAndPlan,
    brief_comment: problem.briefComment,
    custom_problem_type_description: problem.customProblemDescription,
    custom_problem_type_code: problem.problemCode,
    problem_code_location: { id: problem.locationId },
    problem_type: { id: problem.problemId },
    onset: problem.onset,
    id: problem.id,
    resolution: problem.resolution,
    summary: problem.summary,
    included_in_medical_history: problem.includedInMedicalHistory,
    use_custom_problem_type: problem.isCustom,
    create_problem_history: !isAutosave,
    annotation: problem.annotation,
  };

  if (!isNaN(problem.status)) {
    requestPayload.status = problem.status;
  }

  return requestPayload;
};

/* istanbul ignore next */
export const mapProblemTypeSearchResponseToEntity = (
  response: any,
  searchTerm: string,
): Partial<ProblemType> => {
  const locations = response.locations || null;
  const autoCreations = response.auto_creations || [];

  let label: string;
  if (response.problem_codes.length === 1) {
    label = `${problemTypeHighlight(response, searchTerm)} ${
      response.problem_codes[0]
    }`;
  } else {
    label = problemTypeHighlight(response, searchTerm);
  }

  return {
    id: response.id,
    clinicalDescription: response.clinical_description,
    clinicalAbbreviation: response.clinical_abbreviation || null,
    layDescription: response.lay_description,
    importance: response.importance,
    locations: locations
      ? locations.map(location => ({
          id: location.id,
          name: location.name,
          label: `${location.name} ${location.code}`,
          extensions: location.extensions,
        }))
      : null,
    decisionSupport: response.decision_support || null,
    autoCreations: autoCreations.map(autoCreation => {
      if (autoCreation.basic_follow_up_order) {
        const basicFollowUpOrder = autoCreation.basic_follow_up_order;
        return {
          basicFollowUpOrder: {
            intervalType: basicFollowUpOrder.interval_type,
            intervalValue: basicFollowUpOrder.interval_value,
          },
        };
      } else if (autoCreation.visit_follow_up_order) {
        const visitFollowUpOrder = autoCreation.visit_follow_up_order;
        return {
          visitFollowUpOrder: {
            intervalType: visitFollowUpOrder.interval_type,
            intervalValue: visitFollowUpOrder.interval_value,
          },
        };
      }
    }),
    tags: response.tags,
    label: label,
  };
};

/* istanbul ignore next */
export const mapProblemCodeSearchResponseToEntity = (
  response: any,
  searchTerm: string,
): ProblemCustomCodeSelection => {
  const {
    code,
    id,
    detail_description: detailDescription,
    extensions,
  } = response;

  return {
    code,
    detailDescription,
    id,
    label: problemCodeHighlight(response, searchTerm),
    extensions,
  };
};
