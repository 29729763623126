<div om-layout="vertical" om-layout-gutter-large>
  @if (comments | ngrxPush; as comments) {
    <div>
      @for (comment of comments; track comment; let last = $last) {
        <div om-layout="vertical">
          <div om-layout om-layout-align="space-between center" om-layout-gutter>
            <div om-layout om-layout-gutter>
              <strong>{{ comment.createdBy }}</strong>
              <span class="caption" [title]="comment.createdAt | omgDate: 'withTime2y'">
                {{ comment.createdAt | omgDate: 'withTime2y' }}
              </span>
            </div>
            @if (!!comment.actions.delete) {
              <a class="om-link" (click)="onDeleteComment(comment.id)">Delete</a>
            }
          </div>
          <p>{{ comment.body }}</p>
          @if (last) {
            <hr class="-spaced" />
          }
        </div>
      }
    </div>
  } @else {
    <div om-layout om-layout-gutter om-layout-align="center">
      <i class="fa fa-lg fa-spinner fa-pulse"></i>
    </div>
  }
</div>
<label>
  <div om-layout om-layout-align="space-between center">
    <span>Add a new comment</span>
    <span class="caption" [ngClass]="{ error: commentFormModel.form.invalid }">
      {{ maxCommentLength - getCommentLength() }}
      characters remaining
    </span>
  </div>
  <omg-chart-text-box
    [control]="commentFormModel.get('commentBody')"
    quillFormat="text"
    [singleLine]="true"
    [maxLength]="maxCommentLength"
    placeholderText="Write a comment and hit 'enter' to save"
    [insertionEventProps]="{
      component: 'Comments',
      subcomponent: 'Comment Text Box',
    }"
    (enterPressed)="onSaveComment()"
  ></omg-chart-text-box>
</label>
