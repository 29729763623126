<div
  #privacyBanner
  class="banner"
  data-testid="privacy-banner"
>
  <div class="left">
    <div class="icon">
      <i class="fa-solid fa-triangle-exclamation"></i>
    </div>

    <div class="content">
      <strong class="heading">Privacy & Monitoring Reminder</strong>
      <p>
        Your activity is monitored. Access patient records responsibly.
        <span
          [omgPopoverTriggerFor]="privacyBannerPopOver"
          triggerOn="hover"
          class="popover-trigger"
        >
          Why am I seeing this?
        </span>
      </p>
    </div>
  </div>
  <button
    omgButton
    type="button"
    variant="primary"
    (click)="onAcknowledge()"
    data-testid="acknowledge-button"
  >
    Acknowldege
  </button>
</div>

<omg-popover
  placement="bottom"
  #privacyBannerPopOver
>
  <div
    om-layout="vertical"
    om-layout-gutter-large
    class="padding-normal"
    data-cy="privacy-banner-popover"
  >
    <p>
      This is a regular reminder sent to all 1Life users. It's part of our commitment to maintaining patient privacy and
      is not in response to any specific action or concern about your account.
    </p>
  </div>
</omg-popover>
