import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@app/shared/pipes';
import { PushPipe } from '@ngrx/component';
import { ComponentsModule, FormsModule } from '@app/shared';
import { TranscriptFeedbackFormComponent } from './transcript/transcript-feedback-form/transcript-feedback-form.component';
import { HealthscribeBannerV2Component } from './healthscribe-banner/v2/healthscribe-banner-v2.component';
import { TranscriptComponent } from './transcript/transcript.component';
import { TranscriptSectionComponent } from './transcript/transcript-section/transcript-section.component';
import { RecordingManagerComponent } from './recording/recording-manager/recording-manager.component';
import { RecordingControlComponent } from './recording/recording-control/recording-control.component';
import { HealthscribeBannerContainerComponent } from './healthscribe-banner/healthscribe-banner-container.component';
import { SummariesGuard } from '../summaries/shared/summaries.guard';
import { ReadonlyRecordingManagerContainerComponent } from './recording/recording-manager/readonly-container/readonly-recording-manager-container.component';
import { AudioRecorderService } from './recording/audio-recorder.service';
import { HealthscribeBannerV1Component } from '@app/features/healthscribe/healthscribe-banner/v1/healthscribe-banner.component';
import {
  ProblemMatchFeedbackBannerComponent
} from '@app/features/healthscribe/transcript/problem-match-feedback-banner/problem-match-feedback-banner.component';
import {
  ProblemMatchFeedbackModalComponent
} from '@app/features/healthscribe/transcript/problem-match-feedback-modal/problem-match-feedback-modal.component';

@NgModule({
  declarations: [
    HealthscribeBannerV2Component,
    HealthscribeBannerContainerComponent,
    ProblemMatchFeedbackBannerComponent,
    ProblemMatchFeedbackModalComponent,
    TranscriptFeedbackFormComponent,
    TranscriptComponent,
    TranscriptSectionComponent,
    RecordingControlComponent,
    RecordingManagerComponent,
    ReadonlyRecordingManagerContainerComponent,
  ],
  exports: [
    HealthscribeBannerV2Component,
    HealthscribeBannerContainerComponent,
    TranscriptFeedbackFormComponent,
    TranscriptComponent,
    TranscriptSectionComponent,
    RecordingManagerComponent,
    ReadonlyRecordingManagerContainerComponent,
  ],
  providers: [SummariesGuard, AudioRecorderService],
  imports: [
    CommonModule,
    PipesModule,
    PushPipe,
    ComponentsModule,
    FormsModule,
    HealthscribeBannerV1Component,
  ],
})
export class HealthscribeModule {}
