@if ({ note: note$ | ngrxPush, todo: todo$ | ngrxPush }; as container) {
  <omg-card class="workspace-card" [loading]="!container.note?.id || !container.todo">
    <ng-template #loadedRef>
      @if (container.note?.id && container.todo) {
        <omg-note
          [note$]="note$"
          [todo$]="todo$"
          [note]="container.note"
          [todo]="container.todo"
          [patient]="patient$ | ngrxPush"
          [hasRedactNoteFeature]="canRedactNote$ | ngrxPush"
          [noteFormModel]="noteFormModel"
          (closeNote)="onNoteClose()"
          (finish)="onNoteFinish($event)"
          (reopen)="onNoteReopen($event)"
          (delete)="onNoteDelete($event)"
          (unfile)="onNoteUnfile($event)"
          (clone)="onNoteClone($event)"
        ></omg-note>
      }
    </ng-template>
    <ng-template #loadingRef>
      <omg-card-title heading="Note">
        <div class="om-tab-subheading" om-layout om-layout-align="end center">
          <span class="clickable om-icon icon-close" (click)="onNoteClose()" data-cy="close-loading-note"></span>
        </div>
      </omg-card-title>
      <omg-feature-loading-spinner></omg-feature-loading-spinner>
    </ng-template>
  </omg-card>
}
