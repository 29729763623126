import { ErrorHandler, Provider } from '@angular/core';
import { environment } from '@environments/environment';
import { ErrorHandlerService } from './error-handler.service';
import { SentryErrorHandler } from './sentry';

export const errorProviders: Provider[] = [];

if (environment.production) {
  const globalErrorHandlerProvider: Provider = {
    provide: ErrorHandler,
    useClass: ErrorHandlerService,
  };
  errorProviders.push(globalErrorHandlerProvider);
}

if (environment.sentry.enabled) {
  const sentry: Provider = {
    provide: ErrorHandler,
    useValue: SentryErrorHandler,
  };
  errorProviders.push(sentry);
}
