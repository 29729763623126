import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PatientSelectors } from '@app/core';
import { PDMPService } from '@app/modules/shared-rx/services/pdmp.service';
import { Todo } from '@app/modules/todo/shared/todo.type';
import { TodoActions } from '@app/modules/todo/store/todo.actions';
import { TodoSelectors } from '@app/modules/todo/store/todo.selectors';
import { filterTruthy } from '@app/utils';

import { ChangeRxForm } from './../../shared/change-rx-form';
import { ChangeRxFormService } from '../../shared/change-rx-form.service';
import { ChangeRxGuard } from '../../shared/change-rx.guard';
import {
  ChangeRx,
  ChangeRxTypes,
  rxChangeClass,
} from '../../shared/change-rx.type';
import { ChangeRxService } from '../../store/change-rx.service';

@Component({
  selector: 'omg-change-item-detail',
  templateUrl: './change-item-detail.component.html',
  styleUrls: ['./change-item-detail.component.scss'],
  providers: [
    PDMPService,
    ChangeRxService,
    { provide: 'CartDetailsProvider', useExisting: ChangeRxService },
  ],
})
export class ChangeItemDetailComponent implements OnInit {
  changeRx$: Observable<ChangeRx>;
  todo$: Observable<Todo>;
  pediatricVitalsEnabled$: Observable<boolean>;
  showPdmpDialogueBanner$: Observable<boolean>;

  form: ChangeRxForm;
  needsPediatricVitals: boolean;

  changeRxTypes = ChangeRxTypes;

  constructor(
    private changeRxService: ChangeRxService,
    private route: ActivatedRoute,
    private changeRxGuard: ChangeRxGuard,
    private changeRxFormService: ChangeRxFormService,
    private patientSelectors: PatientSelectors,
    private todoSelectors: TodoSelectors,
    private todoActions: TodoActions,
    private pdmpService: PDMPService,
  ) {}

  ngOnInit() {
    this.changeRxGuard
      .canActivate(this.route.snapshot)
      .pipe(
        filterTruthy(),
        tap(() => this.setupSelectors()),
      )
      .subscribe();
  }

  updatePediatricVitalsStatus(hasVitals: boolean) {
    this.needsPediatricVitals = !hasVitals;
  }

  private setupSelectors() {
    this.changeRx$ = this.changeRxService.changeRx$.pipe(
      tap(change => {
        this.form = this.form || this.changeRxFormService.buildForm(change);
        this.loadAndSetTodo(change.id);
      }),
    );

    this.pediatricVitalsEnabled$ = combineLatest([
      this.patientSelectors.isMinor,
      this.changeRx$.pipe(map(changeRx => !!changeRx.rxChangeRequest)),
    ]).pipe(
      map(([isMinor, isRxChangeRequest]) => isMinor && isRxChangeRequest),
    );

    this.showPdmpDialogueBanner$ = this.pdmpService.pdmpReviewRequired$;
  }

  private loadAndSetTodo(id: number) {
    this.todo$ = this.todoSelectors.todoByEntity(id, rxChangeClass);
    this.todoActions.loadTodoByEntity(id, rxChangeClass);
  }
}
