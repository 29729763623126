import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  filter,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { ErrorHandlerService } from '../../errors/error-handler.service';
import { ProfileApiService } from '../shared/profile-api.service';
import {
  GET_PROFILE,
  GetProfile,
  GetProfileError,
  GetProfileSuccess,
} from './profile.actions';
import { ProfileState } from './profile.reducer';
import { selectProfileId } from './profile.selectors';
import { initializeSentryUserContext } from '@app/core/errors/sentry';

@Injectable()
export class ProfileEffects {
  constructor(
    private action$: Actions,
    private store: Store<ProfileState>,
    private profileApiService: ProfileApiService,
    private errorHandler: ErrorHandlerService,
  ) {}

  getProfile$: Observable<Action> = createEffect(() =>
    this.action$.pipe(
      ofType(GET_PROFILE),
      withLatestFrom(this.store.pipe(select(selectProfileId))),
      filter(([action, profileId]: [GetProfile, number]) => !profileId),
      switchMap(() =>
        this.profileApiService.get().pipe(
          tap(profile => initializeSentryUserContext(profile)),
          map((data: any) => new GetProfileSuccess(data)),
          catchError((error: any) =>
            of(new GetProfileError(this.errorHandler.handleErrorSafe(error))),
          ),
        ),
      ),
    ),
  );
}
