import { Injectable } from '@angular/core';
import {
  createFeatureSelector,
  createSelector,
  select,
  Store,
} from '@ngrx/store';
import { distinctUntilChanged, map } from 'rxjs/operators';

import * as utils from '../shared/patient-utils';
import { PatientState, patientStatePath } from './patient.reducer';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { combineLatest, Observable } from 'rxjs';
import { FeatureFlagNames } from '@app/core/feature-flag/shared/feature-flag.type';
import { ProjectKestrelFeatureFlagVariants } from '@app/core/feature-flag/shared/feature-flag-variants.type';
import { PatientWarnings } from '@app/core';

const medicare = 'Medicare';

export const selectPatientState =
  createFeatureSelector<PatientState>(patientStatePath);

export const selectPatient = createSelector(
  selectPatientState,
  state => state && state.entity,
);

export const selectPatientId = createSelector(selectPatient, p => p && p.id);
const selectPatientFhirId = createSelector(selectPatient, p => p && p.fhirId);

export const selectPcpComments = createSelector(
  selectPatient,
  p => p && p.pcpComments,
);

const selectPcpCommentHistories = createSelector(
  selectPatient,
  p => p && p.pcpCommentHistories,
);

const selectPcpCommentLastEditedBy = createSelector(
  selectPatient,
  p => p && p.pcpCommentLastEditedBy,
);

const selectPcpCommentLastUpdatedAt = createSelector(
  selectPatient,
  p => p && p.pcpCommentLastUpdatedAt,
);

export const selectHealthMaintenanceNote = createSelector(
  selectPatient,
  p => p && p.healthMaintenanceNote,
);

export const selectFullName = createSelector(selectPatient, p => {
  const fullNameExists = !!p && !!p.preferredName && !!p.lastName;
  return fullNameExists ? `${p.preferredName} ${p.lastName}` : null;
});

const selectDateOfBirth = createSelector(
  selectPatient,
  p => p && p.dateOfBirth,
);

export const selectGender = createSelector(selectPatient, p => p && p.gender);

export const selectPastOrCurrentElectronicTosAccepted = createSelector(
  selectPatient,
  p => p && p.pastOrCurrentElectronicTosAccepted,
);

export const selectPatientStateName = createSelector(
  selectPatient,
  p =>
    p &&
    p.office &&
    p.office.address &&
    p.office.address.state &&
    p.office.address.state.name,
);

export const selectPatientOffice = createSelector(selectPatient, p => p.office);

export const selectPreferredName = createSelector(
  selectPatient,
  p => p.preferredName,
);

export const selectAddresses = createSelector(selectPatient, p => p.addresses);

const selectFormattedAge = createSelector(
  selectPatient,
  p => p.formattedDisplayAge,
);

export const selectAgeInMonths = createSelector(
  selectPatient,
  p => p && p.ageInMonths,
);

const selectIsUnderTwenty = createSelector(selectPatient, p =>
  utils.isUnderTwenty(p),
);

const selectIsTwentyOneOrOver = createSelector(selectPatient, p =>
  utils.isTwentyOneOrOver(p),
);

const selectIsMinor = createSelector(selectPatient, p => utils.isMinor(p));

const selectIsTeen = createSelector(selectPatient, p => utils.isTeen(p));

const selectIsPreteen = createSelector(selectPatient, p => utils.isPreteen(p));

const selectHasIncompleteDemographics = createSelector(selectPatient, p =>
  utils.hasIncompleteDemographics(p),
);

const selectAcceptsDigitalCommunications = createSelector(selectPatient, p =>
  utils.acceptsDigitalCommunications(p),
);

const selectLimitedAccessMembership = createSelector(selectPatient, p =>
  utils.limitedAccessMembership(p),
);

const selectExpiredMembership = createSelector(selectPatient, p =>
  utils.expiredMembership(p),
);

const selectPatientWarnings = createSelector(selectPatient, p =>
  utils.buildPatientWarnings(p),
);

export const selectPrimaryInsurance = createSelector(
  selectPatient,
  p => p.primaryInsurance,
);

export const selectPrimaryInsuranceType = createSelector(
  selectPatient,
  p => p.primaryInsurance?.type,
);

export const selectIsVirtual = createSelector(selectPatient, p => p.isVirtual);

const selectIsShiftAvailable = createSelector(
  selectPatient,
  p => p.serviceArea.isShiftAvailable,
);

const selectLoading = createSelector(
  selectPatientState,
  state => state.loading,
);

const selectLoadingPcpComments = createSelector(
  selectPatientState,
  state => state.loadingPcpComments,
);

const selectError = createSelector(selectPatientState, state => state.error);

export const selectPatientInfo = createSelector(
  selectPatient,
  selectFormattedAge,
  selectIsTeen,
  selectIsPreteen,
  selectAcceptsDigitalCommunications,
  selectHasIncompleteDemographics,
  selectExpiredMembership,
  selectLimitedAccessMembership,
  (
    patient,
    formattedAge,
    isTeen,
    isPreteen,
    acceptsDigitalCommunications,
    hasIncompleteDemographics,
    expiredMembership,
    limitedAccessMembership,
  ) => ({
    ...patient,
    formattedAge,
    isTeen,
    isPreteen,
    acceptsDigitalCommunications,
    hasIncompleteDemographics,
    expiredMembership,
    limitedAccessMembership,
  }),
);

export const selectVBCEligible = createSelector(
  selectPatient,
  p => p.vbcEligible,
);

/* istanbul ignore next */
@Injectable()
export class PatientSelectors {
  constructor(
    private store: Store<PatientState>,
    private launchDarklyService: LaunchDarklyService,
  ) {}

  get acceptsDigitalCommunications() {
    return this.store.pipe(select(selectAcceptsDigitalCommunications));
  }

  get addresses() {
    return this.store.pipe(select(selectAddresses));
  }

  get ageInMonths() {
    return this.store.pipe(select(selectAgeInMonths));
  }

  get dateOfBirth() {
    return this.store.pipe(select(selectDateOfBirth));
  }

  get expiredMembership() {
    return this.store.pipe(select(selectExpiredMembership));
  }

  get formattedAge() {
    return this.store.pipe(select(selectFormattedAge));
  }

  get fullName() {
    return this.store.pipe(select(selectFullName));
  }

  get gender() {
    return this.store.pipe(select(selectGender));
  }

  get hasIncompleteDemographics() {
    return this.store.pipe(select(selectHasIncompleteDemographics));
  }

  get healthMaintenanceNote() {
    return this.store.pipe(select(selectHealthMaintenanceNote));
  }

  get isUnderTwenty() {
    return this.store.pipe(select(selectIsUnderTwenty));
  }

  get isTwentyOneOrOver() {
    return this.store.pipe(select(selectIsTwentyOneOrOver));
  }

  get isMinor() {
    return this.store.pipe(select(selectIsMinor));
  }

  get isPreteen() {
    return this.store.pipe(select(selectIsPreteen));
  }

  get isTeen() {
    return this.store.pipe(select(selectIsTeen));
  }

  get limitedAccessMembership() {
    return this.store.pipe(select(selectLimitedAccessMembership));
  }

  get loading() {
    return this.store.pipe(select(selectLoading));
  }

  get loadingPcpComments() {
    return this.store.pipe(select(selectLoadingPcpComments));
  }

  get error() {
    return this.store.pipe(select(selectError));
  }

  get office() {
    return this.store.pipe(select(selectPatientOffice));
  }

  get pastOrCurrentElectronicTosAccepted() {
    return this.store.pipe(select(selectPastOrCurrentElectronicTosAccepted));
  }

  get patient() {
    return this.store.pipe(select(selectPatient));
  }

  get patientId() {
    return this.store.pipe(select(selectPatientId));
  }

  get patientFhirId() {
    return this.store.pipe(select(selectPatientFhirId));
  }

  get pcpCommentHistories() {
    return this.store.pipe(select(selectPcpCommentHistories));
  }

  get pcpCommentLastEditedBy() {
    return this.store.pipe(select(selectPcpCommentLastEditedBy));
  }

  get pcpCommentLastUpdatedAt() {
    return this.store.pipe(select(selectPcpCommentLastUpdatedAt));
  }

  get patientInfo() {
    return this.store.pipe(select(selectPatientInfo));
  }

  get patientStateName() {
    return this.store.pipe(select(selectPatientStateName));
  }

  get patientWarnings(): Observable<PatientWarnings> {
    return combineLatest([
      this.store.pipe(select(selectPatientWarnings)),
      this.launchDarklyService
        .variation$<ProjectKestrelFeatureFlagVariants>(
          FeatureFlagNames.enhancedFunctionalityForLimitedAccessUsers,
          'OFF',
        )
        .pipe(map(flag => flag === 'ON VARIANT')),
    ]).pipe(
      map(([warnings, excludeLimitedAccessWarning]) => {
        if (excludeLimitedAccessWarning) {
          const { limitedAccessMember, ...warningsWithoutLimitedAccess } =
            warnings;
          return warningsWithoutLimitedAccess;
        }

        return warnings;
      }),
    );
  }

  get pcpComments() {
    return this.store.pipe(select(selectPcpComments));
  }

  get preferredName() {
    return this.store.pipe(select(selectPreferredName));
  }

  get primaryInsurance() {
    return this.store.pipe(select(selectPrimaryInsurance));
  }

  get primaryInsuranceType() {
    return this.store.pipe(select(selectPrimaryInsuranceType));
  }

  get vbcEligible() {
    return this.store.pipe(select(selectVBCEligible));
  }

  get isVirtual() {
    return this.store.pipe(select(selectIsVirtual));
  }

  get isMedicare() {
    return this.primaryInsuranceType.pipe(
      distinctUntilChanged(),
      map(insurance => insurance === medicare),
    );
  }

  get isShiftAvailable() {
    return this.store.pipe(select(selectIsShiftAvailable));
  }
}
