import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { windowToken } from '@app/shared/window/window.service';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { LoggerService } from '../logger';

import { httpStatus } from '@app/utils';
import { environment } from '@environments/environment';
import { LaunchDarklyService } from '@app/core/launch-darkly/launchdarkly.service';
import { FeatureFlagNames } from '../feature-flag/shared/feature-flag.type';
import {
  buildOmacsErrorDestinationUrl,
  isOmacsError,
} from '../errors/omacs-errors';

@Injectable()
export class ForbiddenInterceptorService implements HttpInterceptor {
  /**
    @deprecated
  */
  restrictedEndpointPatternsToBeDeprecated: RegExp[] = [
    // api/v2/admin/profile should not be here
    new RegExp(/\/api\/v2\/admin\/patients\/\d+$/),
  ];

  // api/v2/admin/profile should not be here
  // Adding this long list of regex to be as strict as possible initially. This will be
  // updated accordingly after analyzing required metrics.
  restrictedEndpointPatterns: RegExp[] = [
    // Simple endpoints
    /^\/api\/v2\/admin\/appointments$/,
    /^\/api\/v2\/admin\/attachments$/,
    /^\/api\/v2\/admin\/comments$/,
    /^\/api\/v2\/admin\/elab_orders$/,
    /^\/api\/v2\/admin\/letter_templates$/,
    /^\/api\/v2\/admin\/patient_medication_regimens$/,
    /^\/api\/v2\/admin\/pending_new_rxes$/,
    /^\/api\/v2\/admin\/procedures$/,

    // Measurement and note types
    /^\/api\/v2\/admin\/measurement_order_type_sources$/,
    /^\/api\/v2\/admin\/measurement_types\/vitals\/\d+$/,
    /^\/api\/v2\/admin\/note_types$/,

    // Order-related endpoints
    /^\/api\/v2\/admin\/orders\/appointment_types$/,
    /^\/api\/v2\/admin\/orders\/relative_providers$/,

    // Endpoints with numeric IDs
    /^\/api\/v2\/admin\/(consult_orders|health_goals|lab_orders|notes|pending_new_rxes|problems|procedure_orders|refill_requests|rx_changes|summaries|todos|vaccine_orders|visit_procedures)\/\d+/,
    /^\/api\/v2\/admin\/(consult_orders|lab_orders|notes|posts|patient_rx_requests|procedure_orders|rx_changes)\/\d+\/(comments|todo)$/,
    /^\/api\/v2\/admin\/lab_orders\/\d+\/check_abn$/,
    /^\/api\/v2\/admin\/problems\/\d+\/problem_histories$/,
    /^\/api\/v2\/admin\/todos\/\d+\/resolve$/,

    // Lab data endpoints
    /^\/api\/v2\/admin\/lab_data\/\d+$/,
    /^\/api\/v2\/admin\/lab_data\/create_manual$/,

    // Patient medication endpoints
    /^\/api\/v2\/admin\/patient_medications\/\d+\/(patient_medication_regimens|rx_history)$/,
    /^\/api\/v2\/admin\/patient_medications\/create_with_associations$/,

    // Patient-related endpoints
    /^\/api\/v2\/admin\/patients\/\d+$/,
    /^\/api\/v2\/admin\/patients\/\d+\/(chart_accesses|health_background|health_goal_screenings|health_goals|history_vaccines|implantable_devices|measurements_data|notes|patient_orders|patient_pharmacies|problems|procedure_orders|smoking_statuses|summaries|vitals)$/,
    /^\/api\/v2\/admin\/patients\/\d+\/family_health_histories\/(family_members|health_background)$/,
    /^\/api\/v2\/admin\/patients\/\d+\/medication_prescriptions\/full_history$/,
    /^\/api\/v2\/admin\/patients\/\d+\/patient_allergies(\/patient_no_known_allergy)?$/,
    /^\/api\/v2\/admin\/patients\/\d+\/patient_medications(\/reconciliation(\/latest|\/notification)?)?$/,
    /^\/api\/v2\/admin\/patients\/\d+\/patient_timeline\/posts(\/\d+(\/comments(\/\d+)?|\/todo)?)?$/,
    /^\/api\/v2\/admin\/patients\/\d+\/refill_requests\/denial_reasons$/,
    /^\/api\/v2\/admin\/patients\/\d+\/rx_changes(\/\d+)?$/,
    /^\/api\/v2\/admin\/patients\/\d+\/rx_changes\/denial_reasons$/,
    /^\/api\/v2\/admin\/patients\/\d+\/rx_checkout\/(pending_new_rxes|refill)$/,
    /^\/api\/v2\/admin\/patients\/\d+\/vaccine_orders\/\d+$/,

    // RX cart endpoints
    /^\/api\/v2\/admin\/patients\/\d+\/rx_carts\/\d+\/(prescribers\/\d+\/prescribing_credentials|update_new)$/,
    /^\/api\/v2\/admin\/patients\/\d+\/rx_carts\/current_(new|renewal)$/,
  ];

  constructor(
    private logger: LoggerService,
    private launchDarklyService: LaunchDarklyService,
    @Inject(windowToken) private window: Window,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (isOmacsError(error)) {
          const destination = buildOmacsErrorDestinationUrl(error);
          this.logger.info(
            `Encounterd OMACS error and re-directing to ${destination}`,
            error?.error,
          );
          this.window.location.href = destination;
          throw error;
        }
        return this.launchDarklyService.initialized$.pipe(
          first(Boolean),
          map(() => {
            const enableCallToOmacs = this.launchDarklyService.variation(
              FeatureFlagNames.enableCallToOmacs,
              false,
            );
            const allowMultipleApisToCallOmacs =
              this.launchDarklyService.variation(
                FeatureFlagNames.allowMultipleApisToCallOmacs,
                false,
              );
            const forbidden = error.status === httpStatus.FORBIDDEN;
            const relevant = !request.params.has('ignore403');
            const checks: boolean[] = [forbidden, relevant];
            const redirect =
              checks.every(Boolean) &&
              this.isEnabledAndRestricted(
                enableCallToOmacs,
                allowMultipleApisToCallOmacs,
                request.url,
              );
            if (redirect) {
              const params = this.getParamsToAppend();
              const patientId = request.url.split('/').pop();
              let destination: string;
              if (
                this.launchDarklyService.variation(
                  FeatureFlagNames.redirectToCazPageWithNoPatientId,
                  false,
                )
              ) {
                destination = `${environment.memberManagementUi.host}/members/restricted/access_denied?${params.toString()}`;
              } else {
                destination = `${environment.memberManagementUi.host}/members/restricted/${patientId}/access_denied?${params.toString()}`;
              }

              this.logger.info('Rerouting to CAZ workflow error: ' + error);
              this.window.location.href = destination;
            }
            throw error;
          }),
        );
      }),
    );
  }

  private isEnabledAndRestricted(
    enableCallToOmacs: boolean,
    allowMultipleApisToCallOmacs: boolean,
    endpoint: string,
  ): boolean {
    return (
      (allowMultipleApisToCallOmacs && this.isRestrictedEndpoint(endpoint)) ||
      (enableCallToOmacs &&
        this.isRestrictedEndpoint(
          endpoint,
          this.restrictedEndpointPatternsToBeDeprecated,
        ))
    );
  }

  public isRestrictedEndpoint(
    endpoint: string,
    restrictedEndpointPatterns: RegExp[] = this.restrictedEndpointPatterns,
  ): boolean {
    const validUrl = URL.canParse(endpoint);
    if (!validUrl) {
      return false;
    }
    const url = new URL(endpoint);
    return restrictedEndpointPatterns.some(pattern =>
      pattern.test(url.pathname),
    );
  }

  private getParamsToAppend(): URLSearchParams {
    const params = new URLSearchParams();
    if (this.window.sessionStorage.getItem('path') !== null) {
      params.append(
        'returnUrl',
        `${this.window.location.origin}${this.window.sessionStorage.getItem('path')}`,
      );
    }
    return params;
  }
}
