@if (!completed) {
  <omg-section-title>
    <div
      om-layout
      om-layout-align="space-between center"
      om-flex="100"
    >
      <span>Chronic Care Management</span>
    </div>
  </omg-section-title>
}
@if (programVisit() && !loadingProgramVisit()) {
  <omg-chronic-care-management-form
    [programVisit]="programVisit()"
    [completed]="completed"
  ></omg-chronic-care-management-form>
}
