export enum FeatureFlagNames {
  alternateBookingFlowWeb = 'alternate_booking_flow_web',
  analytics = 'analytics',

  ascvdRiskCalculatorForPreventionAndScreening = 'ascvd-risk-calculator-for-prevention-and-screening',
  autoAdvancingDueDatesStructuredResults = 'auto-advancing-due-dates-structured-results',
  billingDisallowDocumentUploads = 'billing-disallow-document-uploads',
  bhxFollowUpVisitAppointmentTypeAutocomplete = 'follow-up-visit-appointment-type-autocomplete',
  bhxFollowUpVisitIncludesAdminBookedAppointmentTypes = 'follow-up-visit-includes-admin-booked-appointment-types',
  cehrtMedlinePlusConnectLink = 'cehrt-medline-plus-connect-link',
  connectedDeviceOrders = 'orders-connected-device-orders',
  consultantSearchDefaultSortValue = 'consultant-search-default-sort-value',
  consultantSearchSort = 'consultant-search-sort',
  orderCloningLogic = 'chart-ui-order-cloning-logic',
  consultOrderCloningEnabled = 'chart-ui-consult-order-cloning-enabled',
  contactValueStarRatings = 'contact-value-star-ratings',
  enhancedTimelineSearch = 'enhanced-timeline-search',
  frontendPlatformJadeTheme = 'frontend-platform-jade-theme',
  goodFolksContactSortingOption = 'good-folks-contact-sorting-option',
  growthChartsDownSyndrome = 'growth-charts-down-syndrome',
  linkExternalDocs = 'link-external-docs',
  locationBasedLicensureTaskRouting = 'location-based-licensure-task-routing',
  medsAutoDymoLabel = 'meds-auto-dymo-label',
  medsCreateSuspectedProblems = 'meds-create-suspected-problems',
  medsSmokingStatusCehrtRequirements = 'meds-smoking-status-cehrt-requirements',
  medsFamilyHealthHistory = 'meds-family-health-history',
  medsFamilyHealthHistoryAddToNote = 'meds-family-health-history-add-to-note',
  medsRxChangeEditor = 'meds_rx_change_editor',
  medsRxRenewalRequestEditor = 'meds-rx-renewal-request-editor',
  medsVaccineOrderDeleteAndEdit = 'meds-vaccine-order-delete-and-edit',
  medsVitalsRangeValidation = 'meds-vitals-range-validation',
  medsVaccineGrouping = 'meds-vaccine-grouping',
  orderingElabOrdersController = 'ordering-elab-orders-controller',
  orderingLabcorpApi = 'ordering-labcorp-api',
  orderingEditLabOrderCollectionDateTime = 'ordering-edit-lab-order-collection-date-time',
  orderingMedsRec = 'ordering-meds-rec-launch',
  orderingAllergiesRec = 'ordering-allergies-rec-launch',
  orderingEnableProcedureOrderCloning = 'ordering-enable-procedure-order-cloning',
  orderingDosingRec = 'ordering-dosing-recommendations',
  orderingODF = 'ordering-ondemand-formulary',
  memberManagementUi = 'member-management-ui',
  mixpanelEventsWithExperimentData = 'mixpanel-events-with-experiment-data',
  new1lifeEnableDeletingOfficeNote = 'new-1life-enable-deleting-office-note',
  new1lifeMessagingDeletion = 'new-1life-messaging-deletion',
  noteRedact = 'note-redact',
  patientReportedResultRedesign = 'patient-reported-result-redesign',
  pcpCommentByline = 'pcp-comment-byline',
  problemSortEnabled = 'problem-sort-enabled',
  problemsScrollPositionOnClose = 'problems-scroll-position-on-close',
  problemToMedsLinkingPhases = 'problems-to-meds-linking-phased',
  acoReachEnabled = 'ramp-aco-reach-enabled',
  showBasicFollowUpOrders = 'show-basic-follow-up-orders',
  smartDraft = 'smart-draft',
  smartDraftInChart = 'smart-draft-in-chart',
  vaccineRecommendation = 'vaccine-recommendation',
  taskChainingActionBar = 'task-chaining-action-bar',
  childHeadCirc = 'under-3-yo-head-circumference',
  draftRenewalCheckoutDenialMessage = 'draft-renewal-checkout-denial-message',
  useSurveySystemNameOnelifeUi = 'use-survey-system-name-onelife-ui',
  chartDocumentSummarizationVisible = 'chart-document-summarization-visible',
  chartConversationSummaries = 'chart-conversation-summaries',
  enhancedAPinSummaries = 'enhanced-a-p-in-summaries',
  healthscribeBannerVersionOne = 'healthscribe-banner-version-one',
  healthscribeInChart = 'healthscribe-in-chart',
  createSummaryWithGraphQL = 'create-summary-with-graphql',
  useGqlConsultantAutocomplete = 'use-gql-consultant-autocomplete',
  deprecateProcedureTypesIndex = 'deprecate-procedure-types-index',
  enableCallToOmacs = 'enable-call-to-omacs',
  allowMultipleApisToCallOmacs = 'allow-multiple-apis-to-call-omacs',
  contactFaxOverride = 'ribbon-fax-override',
  redirectToCazPageWithNoPatientId = 'redirect-to-caz-page-with-no-patient-id',
  osProxySummaries = 'os-proxy-summaries',
  chartingTemplatesSource = 'charting-templates-source',
  addAnnotationToProblems = 'add-annotation-to-problems',
  enableProgramsInTheChart = 'enable-programs-in-the-chart',
  admissionAttachment = 'admission-attachment',
  enhancedFunctionalityForLimitedAccessUsers = 'enhanced-functionality-for-limited-access-users',
  useRibbonContactsInSearch = 'use-ribbon-contacts-in-search',
  patientMigrationNoticeEnabled = 'patient-migration-notice-enabled',
  documentsLinkForImportedChirpPatients = 'documents-link-for-imported-chirp-patients',
  externalBrowserUseNewPath = 'external-browser-use-new-path',
  showPrivacyBanner = 'onelife-ui-show-privacy-banner',
}

type ValueOf<T> = T[keyof T];
export type FeatureFlag = ValueOf<FeatureFlagNames>;
