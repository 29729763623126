<div
  class="om-tab-subheading"
  om-layout-gutter
  [formGroup]="form"
  omgTheme
>
  <omg-note-icon
    [tags]="note.noteType.tags"
    [noteType]="note.noteType.name"
  ></omg-note-icon>
  <span
    class="-wrapped-text"
    [title]="note.subject"
    om-flex
    om-layout="vertical"
  >
    @if (!editable) {
      <span class="note-title">{{ note.subject | slice: 0 : 80 }}</span>
    }
    @if (editable) {
      <omg-editable-label
        formControlName="subject"
        om-flex
        (focusOn)="trackTitleSelected()"
        (focusOut)="trackTitleUnselected()"
      ></omg-editable-label>
    }
    <omg-note-history
      [note]="note"
      [todo]="todo"
    ></omg-note-history>
  </span>

  <!-- Comment icon / toggle -->
  <omg-collapse-toggle
    [collapseProvider]="commentsCollapseRef"
    om-layout
    om-layout-gutter-mini
    om-layout-align="space-between center"
    (click)="trackCommentClicked()"
    class="collapse-with-flex"
  >
    <omg-collapsed
      [collapseProvider]="commentsCollapseRef"
      disableAnimation="true"
    >
      <span class="om-icon icon-comment clickable"></span>
    </omg-collapsed>
    <omg-expanded
      [collapseProvider]="commentsCollapseRef"
      disableAnimation="true"
    >
      <span class="om-icon icon-comment-filled clickable"></span>
    </omg-expanded>
    <span>{{ note.totalComments }}</span>
  </omg-collapse-toggle>

  @if (noteHasDocuments) {
    <span
      omgTooltip
      tooltipPosition="left"
      omgStopEvent="click"
      data-cy="timeline-tooltip-text"
      [ngClass]="toolTipIcon()"
      [tooltipText]="toolTipText()"
      class="expand-timeline-icon"
      (click)="setExpanded()"
    ></span>
  }

  <span
    om-flex="5"
    class="om-icon icon-close clickable"
    omgStopEvent="click"
    (click)="onClose()"
  ></span>
</div>
