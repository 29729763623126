@if (documentSummarizationVisible) {
  <div class="document-summary">
    <ng-container *ngrxLet="summary$ as summary; suspenseTpl: loading">
      <!--  `summary` is undefined for some reason. Multiple parts of this depend on `summary` being defined, so wrap this all in an if. -->
      @if (summary) {
        <omg-collapse
          [expanded]="true"
          [disabled]="!summary.content.detailedSummary"
          (analyticsExpandedChange)="onViewerCollapseToggle($event)"
        >
          <omg-collapse-toggle>
            <div om-layout om-layout-align="start center">
              <ng-container *ngTemplateOutlet="heading"></ng-container>
              @if (summary.content.detailedSummary) {
                <span>
                  <omg-collapsed [disableAnimation]="true">
                    <i class="fa fa-chevron-down"></i>
                  </omg-collapsed>
                  <omg-expanded [disableAnimation]="true">
                    <i class="fa fa-chevron-up"></i>
                  </omg-expanded>
                </span>
              }
            </div>
          </omg-collapse-toggle>

          <omg-expanded>
            @if (summary.content.detailedSummary) {
              <div>
                <div class="document-summary-text" [omgTrack]="{ event: 'copy', action: 'Document Summary Copied' }">
                  {{ summary.content.detailedSummary }}
                </div>
                <omg-document-summary-timestamp [summary]="summary" />
              </div>
            } @else {
              <omg-document-summary-unavailable />
            }
          </omg-expanded>

          <omg-collapsed>
            @if (summary.content.detailedSummary) {
              <div
                class="document-summary-text-collapsed"
                [omgTrack]="{ event: 'copy', action: 'Document Summary Copied' }"
              >
                {{ summary.content.detailedSummary }}
              </div>
            } @else {
              <omg-document-summary-unavailable />
            }
          </omg-collapsed>
        </omg-collapse>

        @if (summary.content.detailedSummary) {
          <omg-document-summary-feedback [summaryId]="summary.id" (feedbackSubmitted)="onFeedbackSubmitted($event)" />
        }
      }
    </ng-container>

    <ng-template #heading>
      <i class="title-wand om-icon icon-magic"></i>
      <span class="document-summary-title">AI Document Summary</span>
    </ng-template>

    <ng-template #loading>
      <div om-layout om-layout-align="start center">
        <ng-container *ngTemplateOutlet="heading"></ng-container>
      </div>
      <omg-document-summary-loading />
    </ng-template>
  </div>
}
