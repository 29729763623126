import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import * as Sentry from '@sentry/angular-ivy';
import { inject, Injectable } from '@angular/core';
import { ConfigService } from '../config';

@Injectable({
  providedIn: 'root',
})
export class RealUserMonitoring {
  readonly client: AwsRum | undefined;
  private readonly config: ConfigService = inject(ConfigService);

  constructor() {
    try {
      const config: AwsRumConfig = {
        enableRumClient: this.config.environment.rum?.enabled ?? false,
        sessionSampleRate: this.config.environment.rum?.sampleRate,
        identityPoolId: this.config.environment.rum?.identityPoolId,
        endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
        telemetries: [],
        allowCookies: false, // cwrum sets some cookies with longer-lived user IDs and session IDs, not only do we not want those, they aren't important since we're a SPA
        enableXRay: false,
        pageIdFormat: 'HASH', // by setting this to HASH, we can ensure that the page URL is not logged
      };

      const APPLICATION_ID: string = this.config.environment.rum?.applicationId;
      const APPLICATION_VERSION: string = this.config.environment.codeVersion;
      const APPLICATION_REGION: string = 'us-west-2';

      this.client = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config,
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  /**
   * Records an event to CloudWatch RUM.
   *
   * Event type should be in reverse DNS form
   * Event type should be prefixed with `com.onemedical.ui`.
   * Example: `com.onemedical.ui.page_loaded`
   *
   * @param eventType Name of the event.
   * @param eventData Metadata to send with the event.
   */
  recordEvent(eventType: string, eventData: object): void {
    this.client?.recordEvent(eventType, eventData);
  }
}
