import { FsaAction } from '@app/core';
import { StateActions } from '@app/core/store/shared/state';

import {
  RxCartTypes,
  RxNotificationSubscription,
  RxVerification,
  RxVerificationDevicesResponse,
  RxVerificationErrors,
  RxVerifyPayload,
  RxVerifyResponse,
} from '../shared/rx-verification.type';

export enum RxVerificationActionTypes {
  InitializeState = '[RxVerification] Initialize State',
  LoadDevices = '[RxVerification] Load Devices',
  LoadDevicesSuccess = '[RxVerification] Load Devices Success',
  Verify = '[RxVerification] Verify Rx',
  VerificationSuccess = '[RxVerification] Verification Success',
  VerificationError = '[RxVerification] Verification Error',
  SubscribeToNotifications = '[RxVerification] Subscribe To Notifications',
  UnsubscribeFromNotifications = '[RxVerification] Unsubscribe From Notifications',
  UpdateNotificationSubscription = '[RxVerification] Update Notification Subscription',
  MarkRxCartReadyToSign = '[RxVerification] Mark Rx Cart Ready To Sign',
  MarkRxCartReadyToSignResult = '[RxVerification] Mark Rx Cart Ready To Sign Result',
}

export class InitializeState implements FsaAction {
  readonly type = RxVerificationActionTypes.InitializeState;
  error = false;

  constructor() {}
}

export class LoadDevices implements FsaAction<RxCartTypes> {
  readonly type = RxVerificationActionTypes.LoadDevices;
  error = false;

  constructor(public payload: RxCartTypes) {}
}

export class LoadDevicesSuccess
  implements FsaAction<RxVerificationDevicesResponse>
{
  readonly type = RxVerificationActionTypes.LoadDevicesSuccess;
  error = false;

  constructor(public payload: RxVerificationDevicesResponse) {}
}

export class Verify implements FsaAction<RxVerifyPayload> {
  readonly type = RxVerificationActionTypes.Verify;
  error = false;

  constructor(public payload: RxVerifyPayload) {}
}

export class VerificationSuccess implements FsaAction<RxVerifyResponse> {
  readonly type = RxVerificationActionTypes.VerificationSuccess;
  error = false;

  constructor(public payload: RxVerifyResponse) {}
}

export class VerificationError implements FsaAction<RxVerificationErrors> {
  readonly type = RxVerificationActionTypes.VerificationError;
  error = true;

  constructor(public payload: RxVerificationErrors) {}
}

export class SubscribeToNotifications implements FsaAction<string> {
  readonly type = RxVerificationActionTypes.SubscribeToNotifications;
  error = false;

  constructor(public payload: string) {}
}

export class UpdateNotificationSubscription
  implements FsaAction<RxNotificationSubscription>
{
  readonly type = RxVerificationActionTypes.UpdateNotificationSubscription;
  error = false;

  constructor(public payload: RxNotificationSubscription) {}
}

export class UnsubscribeFromNotifications implements FsaAction {
  readonly type = RxVerificationActionTypes.UnsubscribeFromNotifications;
  error = false;

  constructor() {}
}

export class MarkReadyToSign implements FsaAction<number> {
  readonly type = RxVerificationActionTypes.MarkRxCartReadyToSign;
  error = false;

  constructor(public payload: number) {}
}
export class MarkReadyToSignResult implements FsaAction<boolean> {
  readonly type = RxVerificationActionTypes.MarkRxCartReadyToSignResult;
  error = false;

  constructor(public payload: boolean) {}
}

export type RxVerificationCustomActions =
  | InitializeState
  | LoadDevices
  | LoadDevicesSuccess
  | Verify
  | VerificationError
  | SubscribeToNotifications
  | UnsubscribeFromNotifications
  | UpdateNotificationSubscription
  | VerificationSuccess;

export class RxVerificationActions extends StateActions<RxVerification> {
  initializeState(): void {
    this.store.dispatch(new InitializeState());
  }

  loadDevices(cartType: RxCartTypes): void {
    this.store.dispatch(new LoadDevices(cartType));
  }

  verify(payload: RxVerifyPayload): void {
    this.store.dispatch(new Verify(payload));
  }

  unsubscribeFromNotifications(): void {
    this.store.dispatch(new UnsubscribeFromNotifications());
  }

  markReadyToSign(rxCartId): void {
    this.store.dispatch(new MarkReadyToSign(rxCartId));
  }
}
