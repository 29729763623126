@if (!loadingPDF) {
  <div
    [omgRecordEventOn]="{
      listenFor: 'init',
      eventName: 'com.onemedical.ui.letter_loaded',
      eventData: { component: 'Letter' },
    }"
  >
    <form [omgForm]="letterFormModel" [formGroup]="letterFormModel.form">
      <omg-expanded [collapseProvider]="commentsCollapseRef">
        <omg-comments
          [commentable]="commentable$ | ngrxPush"
          (commentAdded)="onCommentUpdate('add')"
          (commentRemoved)="onCommentUpdate('remove')"
        ></omg-comments>
      </omg-expanded>

      @if (letterHasIncompleteTodo) {
        <omg-task-assignment
          [assigneeFieldAnalyticsEventProps]="{
            workflow: 'Charting',
            component: 'Official Letter',
            subcomponent: 'Assigned To',
            activeCharting: true,
            noteId: (letterId$ | ngrxPush),
          }"
          [priorityCheckboxAnalyticsEventProps]="{
            workflow: 'Charting',
            component: 'Official Letter',
            subcomponent: 'High Priority Checkbox',
            activeCharting: true,
            noteId: (letterId$ | ngrxPush),
          }"
          [todo]="todo$"
        ></omg-task-assignment>
      }

      <label class="padding-normal" om-layout="vertical" om-layout-gutter>
        <span>Apply Template</span>
        <omg-auto-complete
          placeholder="Search..."
          (changeAutocomplete)="applyTemplate($event)"
          [items]="letterTemplatesDropdown$ | ngrxPush"
          bindLabel="label"
          bindValue="id"
          trackByKey="id"
          data-cy="official-letter-type-dropdown"
        ></omg-auto-complete>
      </label>

      <div om-layout="vertical" om-layout-fill om-layout-gutter-mini>
        <omg-section-title>Letter</omg-section-title>
        <div class="letterhead padding-normal" om-layout="vertical" om-layout-gutter-large>
          <img src="assets/img/fullname-OM-logo.9d984378.svg" alt="One Medical" />
          <div class="letterhead-address">
            {{ userInfo.officeAddress }}
            <br />
            {{ userInfo.officeContactNumbers }}
          </div>
        </div>
        <div class="padding-normal" om-layout="vertical" om-layout-gutter-large>
          <omg-rich-text-editor
            [allowHyperlinks]="false"
            formControlName="body"
            placeholderText="Choose a template above, or compose your letter here..."
          ></omg-rich-text-editor>
          <div class="signature padding-normal">
            {{ userInfo.userSignatureInfo?.displayName }}
            @if (userInfo.userSignatureInfo?.npi) {
              <div>NPI: {{ userInfo.userSignatureInfo.npi }}</div>
            }
          </div>
        </div>
      </div>
    </form>
  </div>
} @else {
  <div class="loading-pdf" om-layout-align="space-around center" om-layout-gutter-large>
    <i class="fa fa-lg fa-spinner fa-pulse"></i>
    <p>The PDF is being created and will display shortly.</p>
  </div>
}
