<omg-action-bar #actionBar>
  <ng-container left-buttons>
    <div om-layout-gutter>
      <button
        omgButton
        type="button"
        variant="primary"
        data-cy="change-approve-action-btn"
        (click)="openApprovalModal(true)"
        [disabled]="disabledApprovalAction"
      >
        Approve
      </button>
      <button
        omgButton
        type="button"
        variant="secondary"
        (click)="openDenyDrawer()"
        [disabled]="disabledDenialAction"
        data-cy="change-deny-action-btn"
      >
        Deny
      </button>
    </div>
  </ng-container>
  <ng-container right-buttons>
    <button
      omgButton
      class="padding-normal-horizontal"
      variant="link"
      routerLink="../../list"
      data-cy="change-back-action-btn"
    >
      Back To List
    </button>
  </ng-container>
  <omg-rx-item-deny-drawer
    om-layout-fill
    [isChange]="true"
    (cancel)="closeDenyDrawer()"
    (deny)="openApprovalModal(false, $event)"
  ></omg-rx-item-deny-drawer>
</omg-action-bar>
