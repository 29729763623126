import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { AppState } from '@app/core/store/store/app-store.reducer';

import { updateProgramVisit } from './../../store/program-visit.actions';
import { ProgramVisit } from '../../shared/program-visit.type';

const emptyStringValidator: ValidatorFn = (
  control: AbstractControl,
): ValidationErrors | null => {
  const value = control.value;

  return value && !!value.trim() ? null : { required: true };
};

@Component({
  selector: 'omg-chronic-care-management-form',
  templateUrl: './chronic-care-management-form.component.html',
  styleUrls: ['./chronic-care-management-form.component.scss'],
})
export class ChronicCareManagementFormComponent
  implements OnInit, OnDestroy, OnChanges
{
  @Input() programVisit: Pick<
    ProgramVisit,
    'id' | 'formData' | 'summaryOfVisit'
  >;
  @Input() completed: boolean;

  chronicCareManagementTimeControl = new UntypedFormControl(null, {
    validators: [Validators.required, Validators.min(1)],
    updateOn: 'change',
  });

  summaryControl = new UntypedFormControl('', {
    validators: [Validators.required, emptyStringValidator],
    updateOn: 'change',
  });

  private unsubscribe = new Subject<void>();

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.setupTimeControl();
    this.setupSummaryControl();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges({ programVisit }: SimpleChanges) {
    if (programVisit.isFirstChange()) {
      this.chronicCareManagementTimeControl.setValue(
        this.programVisit?.formData?.time,
        { emitEvent: false },
      );
      this.summaryControl.setValue(this.programVisit?.summaryOfVisit, {
        emitEvent: false,
      });
    }
  }

  private setupTimeControl() {
    this.chronicCareManagementTimeControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(time => {
        this.store.dispatch(
          updateProgramVisit({
            id: Number(this.programVisit.id),
            formData: { time: time },
          }),
        );
      });
  }

  private setupSummaryControl() {
    this.summaryControl.valueChanges
      .pipe(
        takeUntil(this.unsubscribe),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(summary => {
        summary = summary?.trim() || null;
        if (summary === this.programVisit.summaryOfVisit) {
          return;
        }
        this.store.dispatch(
          updateProgramVisit({
            id: Number(this.programVisit.id),
            summaryOfVisit: summary,
          }),
        );
      });
  }

  onChronicCareManagmentTimeBlur() {
    const timeValue = this.chronicCareManagementTimeControl.value || null;

    this.store.dispatch(
      updateProgramVisit({
        id: Number(this.programVisit.id),
        formData: { time: timeValue },
      }),
    );
  }

  onSummaryBlur() {
    const summaryValue = this.summaryControl.value?.trim() || null;
    if (summaryValue === this.programVisit.summaryOfVisit) {
      return;
    }
    this.store.dispatch(
      updateProgramVisit({
        id: Number(this.programVisit.id),
        summaryOfVisit: summaryValue,
      }),
    );
  }

  showErrorForControl(formControl: UntypedFormControl): boolean {
    return formControl.invalid && (formControl.dirty || formControl.touched);
  }

  get chronicCareFormValid(): boolean {
    return (
      this.chronicCareManagementTimeControl.valid && this.summaryControl.valid
    );
  }

  markFormAsTouched() {
    this.chronicCareManagementTimeControl.updateValueAndValidity();
    this.summaryControl.updateValueAndValidity();
  }
}
