<omg-card class="workspace-card" omgTheme [loading]="!(noteTypes | ngrxPush)">
  <omg-card-title heading="Create a new note"></omg-card-title>
  <ng-template #loadedRef>
    <omg-card-body
      [omgRecordEventOn]="{
        listenFor: 'init',
        eventName: 'com.onemedical.ui.workspace_loaded',
        eventData: { component: 'New Workspace Item' },
      }"
    >
      <div
        class="om-list"
        om-layout="vertical"
        data-cy="new-workspace-items"
        om-layout-fill
        *ngIf="noteTypes | ngrxPush as noteTypes"
      >
        <button
          *ngFor="let type of noteTypes.summaryNoteTypes"
          om-layout
          om-layout-gutter
          om-layout-align="start center"
          data-cy="create-new-summary"
          (click)="newSummary(type)"
          class="om-item -menu-option"
        >
          <omg-note-icon [tags]="type.tags" [noteType]="type.name"></omg-note-icon>
          <span>{{ type.name }}</span>
        </button>
        <button
          *ngFor="let type of noteTypes.miscTaskNoteTypes"
          om-layout
          om-layout-gutter
          om-layout-align="start center"
          data-cy="create-new-task-note"
          class="om-item -menu-option"
          (click)="newMiscTask(type)"
        >
          <omg-note-icon [tags]="type.tags" [noteType]="type.name" [inheritColor]="true"></omg-note-icon>
          <span>{{ type.name }}</span>
        </button>
        <button
          *ngFor="let type of noteTypes.letterNoteTypes"
          om-layout
          om-layout-gutter
          om-layout-align="start center"
          class="om-item -menu-option"
          (click)="newLetter(type)"
        >
          <omg-note-icon [tags]="type.tags" [noteType]="type.name" [inheritColor]="true"></omg-note-icon>
          <span>{{ type.name }}</span>
        </button>
        <button
          om-layout
          om-layout-gutter
          om-layout-align="space-between center"
          (click)="newMessage()"
          [disabled]="!(patientAcceptsDigitalCommunications | ngrxPush)"
          class="om-item -menu-option"
          [omgTrack]="{
            event: 'click',
            action: 'Message Started',
          }"
        >
          <span om-layout om-layout-gutter om-layout-align="start center">
            <span class="om-icon icon-message-large"></span>
            <span>Message</span>
          </span>
          <p class="errors">{{ patientWarnings | ngrxPush }}</p>
        </button>
        <button
          om-layout
          om-layout-gutter
          om-layout-align="space-between center"
          (click)="newManualResultEntry()"
          class="om-item -menu-option"
        >
          <span om-layout om-layout-gutter om-layout-align="start center">
            <span class="om-icon icon-lab-report-large"></span>
            <span>Enter COVID Results</span>
          </span>
        </button>
        <button
          om-layout
          om-layout-gutter
          om-layout-align="space-between center"
          data-cy="create-new-task"
          (click)="newPersonalTask()"
          class="om-item -menu-option"
        >
          <span om-layout om-layout-gutter om-layout-align="start center">
            <span class="om-icon icon-misc-task-large"></span>
            <span>Personal Task</span>
          </span>
        </button>
      </div>
    </omg-card-body>
  </ng-template>
  <ng-template #loadingRef>
    <omg-skeleton-repeater [repeat]="9" om-layout="vertical" om-layout-fill class="om-list">
      <ng-template #repeaterTemplate>
        <div class="om-item" om-layout om-layout-gutter om-layout-align="start center">
          <omg-skeleton class="padding-half-vertical" size="25px"></omg-skeleton>
          <omg-skeleton
            om-flex
            class="padding-normal-horizontal"
            height="16px"
            [randomWidthPercentage]="true"
            [minRandomWidth]="20"
            [maxRandomWidth]="40"
          ></omg-skeleton>
        </div>
      </ng-template>
    </omg-skeleton-repeater>
  </ng-template>
</omg-card>
