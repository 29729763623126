/* eslint-disable */
// @ts-nocheck
/*
====================================================
THIS IS AN AUTOGENERATED FILE
DO NOT EDIT. 
Update with `npm run graphql-codegen`
====================================================
*/
import * as Types from '../../../../graphql/onelife.type';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import { Apollo, Query, Mutation, Subscription } from 'apollo-angular';
export type StartStreamingSessionMutationVariables = Types.Exact<{
  input: Types.StartStreamingSessionInput;
}>;


export type StartStreamingSessionMutation = { __typename?: 'Mutation', startStreamingSession?: { __typename?: 'StartStreamingSessionPayload', success: boolean, appointmentRecording?: { __typename?: 'AppointmentRecording', id: string, streamEndsAt?: string | null } | null } | null };

export const StartStreamingSessionDocument = gql`
    mutation StartStreamingSession($input: StartStreamingSessionInput!) {
  startStreamingSession(input: $input) {
    success
    appointmentRecording {
      id
      streamEndsAt
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StartStreamingSessionMutationService extends Mutation<StartStreamingSessionMutation, StartStreamingSessionMutationVariables> {
    document = StartStreamingSessionDocument;
    client = 'onelife';
    constructor(apollo: Apollo) {
      super(apollo);
    }
  }