import { environment } from '@environments/environment';

/**
 * Common headers used to identify applications to our APIs
 */
export const ClientApplicationHttpHeaders = {
  'x-platform': 'onelife-ui',
  'x-application-id': 'onelife-ui',
  'x-application-version': environment.codeVersion,
};
